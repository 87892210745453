import React from 'react';
import { NavLink } from 'react-router-dom';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Button from '@mui/material/Button';
import AdminAuth from '../hooks/AdminAuth';
import useAuth from '../hooks/useAuth';
import { useNavigate } from 'react-router';
import { Tooltip, tooltipClasses } from '@mui/material';
import { styled } from '@mui/system';
import logo from "../assets/logo.webp";


const CustomTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} arrow classes={{ popper: className }} />
))({
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: 'black',
        fontSize: '12px'
    },
    [`& .${tooltipClasses.arrow}`]: {
        color: 'black',
    },
});

function Topbar() {
    const { currentUser, setIsAuthenticated } = useAuth();
    const navigate = useNavigate();

    const handleLogout = () => {
        localStorage.removeItem('token');
        setIsAuthenticated(false);
        navigate('/');
    };

    return (
        <AppBar position="static">
            <Container maxWidth="xl">
                <Toolbar disableGutters>
                    <NavLink to="/" style={{ textDecoration: 'none', color: '#000', backgroundColor:'#FFF'}}>
                        <p href="/" className="flex items-center space-x-1 rtl:space-x-reverse" style={{marginRight:'25px'}}>
                            <img src={logo} alt="logo" className="h-12 lg:h-16 w-auto" />
                            <span className='self-center text-lg lg:text-2xl font-semibold whitespace-nowrap' >MAILI</span>
                        </p>

                    </NavLink>
                    <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
                        <CustomTooltip title="Click To Generate Primary or Product QR Codes." arrow>
                            <NavLink
                                to="/primaryQR"
                                style={{ textDecoration: 'none', color: '#FFF' }}
                            >
                                {({ isActive }) => (
                                    <Button sx={{ my: 2, color: 'white', display: 'block' }} style={{
                                        backgroundColor: isActive ? "#000" : ""
                                    }}>Primary-QR</Button>
                                )}
                            </NavLink>
                        </CustomTooltip>
                        <CustomTooltip title="Click to Generate Secondary or Container QR Codes" arrow>
                            <NavLink
                                to="/containerQR"
                                style={{ textDecoration: 'none', color: '#FFF' }}
                            >
                                {({ isActive }) => (
                                    <Button sx={{ my: 2, color: 'white', display: 'block' }} style={{
                                        backgroundColor: isActive ? "#000" : ""
                                    }}> Container-QR</Button>
                                )}
                            </NavLink>
                        </CustomTooltip>
                        <CustomTooltip title="Click to Know QR Codes History" arrow>
                            <NavLink
                                to="/qr-report"
                                style={{ textDecoration: 'none', color: '#FFF' }}
                            >
                                {({ isActive }) => (
                                    <Button sx={{ my: 2, color: 'white', display: 'block' }} style={{
                                        backgroundColor: isActive ? "#000" : ""
                                    }}>  QR-Report</Button>
                                )}
                            </NavLink>
                        </CustomTooltip>
                        <AdminAuth>
                            <NavLink
                                to="/create-product"
                                style={{ textDecoration: 'none', color: '#FFF' }}
                            >
                                {({ isActive }) => (
                                    <Button sx={{ my: 2, color: 'white', display: 'block' }} style={{
                                        backgroundColor: isActive ? "#000" : ""
                                    }}> Create-Product</Button>
                                )}
                            </NavLink>
                            <NavLink
                                to="/user-report"
                                style={{ textDecoration: 'none', color: '#FFF' }}
                            >
                                {({ isActive }) => (
                                    <Button sx={{ my: 2, color: 'white', display: 'block' }} style={{
                                        backgroundColor: isActive ? "#000" : ""
                                    }}>  User-Report</Button>
                                )}
                            </NavLink>
                            <NavLink
                                to="/product-report"
                                style={{ textDecoration: 'none', color: '#FFF' }}
                            >
                                {({ isActive }) => (
                                    <Button sx={{ my: 2, color: 'white', display: 'block' }} style={{
                                        backgroundColor: isActive ? "#000" : ""
                                    }}> Product-Report</Button>
                                )}
                            </NavLink>
                        </AdminAuth>
                        <CustomTooltip title="Click to Verify Individual Primary/Secondary QR Codes" arrow>
                            <NavLink
                                to="/verify-details"

                                style={{ textDecoration: 'none', color: '#FFF' }}
                            >
                                {({ isActive }) => (
                                    <Button sx={{ my: 2, color: 'white', display: 'block' }} style={{
                                        backgroundColor: isActive ? "#000" : ""
                                    }}>VERIFY-DETAILS</Button>
                                )}

                            </NavLink>
                        </CustomTooltip>
                        {currentUser.userrole === 'CLIENT' ? (
                            <CustomTooltip title="Click to Change Your Current Login Password" arrow>

                                <NavLink
                                    to="/change-password"

                                    style={{ textDecoration: 'none', color: '#FFF' }}
                                >
                                    {({ isActive }) => (
                                        <Button sx={{ my: 2, color: 'white', display: 'block' }} style={{
                                            backgroundColor: isActive ? "#000" : ""
                                        }}> Change Password</Button>
                                    )}

                                </NavLink>
                            </CustomTooltip>
                        ) : null}

                        <NavLink
                            to="/login"
                            style={{ textDecoration: 'none', color: '#FFF' }}
                        >
                            <Button
                                onClick={handleLogout}
                                sx={{ my: 2, color: 'white', display: 'block' }}
                            >
                                LOGOUT
                            </Button>
                        </NavLink>
                    </Box>
                </Toolbar>
            </Container>
        </AppBar>
    );
}

export default Topbar;
