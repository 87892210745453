import React, { useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import TableHead from '@mui/material/TableHead';
import Topbar from "../components/Topbar";
import PropTypes from 'prop-types';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableFooter from '@mui/material/TableFooter';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import IconButton from '@mui/material/IconButton';
import FirstPageIcon from '@mui/icons-material/FirstPage';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import LastPageIcon from '@mui/icons-material/LastPage';
import Button from '@mui/material/Button';
import UserGuard from "../hooks/UserGuard";
import useAuth from '../hooks/useAuth'
import { styled } from '@mui/material/styles';
import Loading from "../components/Loading";
import moment from "moment";
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import TextField from '@mui/material/TextField';
import FormControl from '@mui/material/FormControl';
import { CSVLink } from 'react-csv'
import { generatePDF, generatev2PDF, generatev3PDF } from '../utils/pdfUtils';
import { Typography } from "@mui/material";



const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
}));


function TablePaginationActions(props) {
    const theme = useTheme();
    const { count, page, rowsPerPage, onPageChange } = props;

    const handleFirstPageButtonClick = (event) => {
        onPageChange(event, 0);
    };

    const handleBackButtonClick = (event) => {
        onPageChange(event, page - 1);
    };

    const handleNextButtonClick = (event) => {
        onPageChange(event, page + 1);
    };

    const handleLastPageButtonClick = (event) => {
        onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
    };

    return (
        <Box sx={{ flexShrink: 0, ml: 2.5 }}>
            <IconButton
                onClick={handleFirstPageButtonClick}
                disabled={page === 0}
                aria-label="first page"
            >
                {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
            </IconButton>
            <IconButton
                onClick={handleBackButtonClick}
                disabled={page === 0}
                aria-label="previous page"
            >
                {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
            </IconButton>
            <IconButton
                onClick={handleNextButtonClick}
                disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                aria-label="next page"
            >
                {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
            </IconButton>
            <IconButton
                onClick={handleLastPageButtonClick}
                disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                aria-label="last page"
            >
                {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
            </IconButton>
        </Box>
    );
}

TablePaginationActions.propTypes = {
    count: PropTypes.number.isRequired,
    onPageChange: PropTypes.func.isRequired,
    page: PropTypes.number.isRequired,
    rowsPerPage: PropTypes.number.isRequired,
};


const GenerateQRPDF = () => {
    let ref = useRef({});
    const { state } = useLocation();
    const navigate = useNavigate();
    const isContainer = state.isContainer;
    const [containerEnabled, setContainerEnabled] = React.useState(false);
    const groupName = state.groupName;
    const [rows, setRows] = React.useState(state.qrCodeArray);
    const product = state.productDetail;
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(5);
    const [companyName, setCompanyName] = React.useState("");
    const [loading, setLoading] = React.useState([])
    const [filter, setFilter] = React.useState("");
    const [pageLoading, setPageLoading] = useState(false);
    const [startSN, setStartSN] = useState(null);
    const [endSN, setEndSN] = useState(null);
    const [printMRP, setPrintMRP] = useState(false);
    const [mrp, setMRP] = useState("");
    const [open, setOpen] = React.useState(false);
    const [snackbarMessage, setSnackbarMessage] = React.useState("")

    const handleBackButton = () => {
        navigate(-1);
    }

    const emptyRows =
        page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const handlePrintMRP = (event) => {
        setPrintMRP(event.target.checked);
    };

    const handleStartSNChange = (event) => {
        setStartSN(event.target.value);
    };

    const handleEndSNChange = (event) => {
        setEndSN(event.target.value);
    };

    const handleMRPChange = (event) => {
        setMRP(event.target.value);
    };


    const convertQRCodeArray = (qrList) => {
        const resArray = [];
        if (String(containerEnabled) === "false") {
            for (let i = 0; i < qrList.length; i++) {
                let dateformat = moment(qrList[i].createdat).tz('Asia/Kolkata')
                    .format('DD/MM/YYYY HH:mm');
                let containerRange = "";
                if (qrList[i].containerrange.length !== 0) {
                    containerRange = String("[ " + String(qrList[i].containerrange[0]) + "-" + String(qrList[i].containerrange[1]) + " ]");
                }
                let qrObj = {};
                if (groupName === 'SEEDS' || groupName === 'FERTILIZER' || groupName === 'PESTICIDES' || groupName === 'CHEMICALS') {
                    qrObj = {
                        SerialNumber: qrList[i].serialnumber,
                        BatchNumber: qrList[i].batchnumber,
                        ManufactureDate: qrList[i].manufacturedate,
                        ExpiryDate: qrList[i].expirydate,
                        ContainerQRCode: qrList[i].containerqrcode === "" ? "" : "\"=\"" + "00" + qrList[i].containerqrcode,
                        ContainerRange: containerRange,
                        QRNumber: "\"=\"" + "01" + String(qrList[i].qrcode),
                        createdat: "\"=\"" + dateformat,
                    }
                }
                else if (groupName === 'Farm Machinery Part-A') {
                    qrObj = {
                        SerialNumber: qrList[i].serialnumber,
                        MachineSerialNumber: qrList[i].serialnumberofthemachinery,
                        YearOfManufacture: qrList[i].yearofmanufacturing,
                        QRNumber: "\"=\"" + "01" + String(qrList[i].qrcode),
                        MRP: qrList[i].mrp,
                        createdat: "\"=\"" + dateformat,
                    }
                }
                else if (groupName === 'Farm Machinery Part-B') {
                    qrObj = {
                        SerialNumber: qrList[i].serialnumber,
                        MachineSerialNumber: qrList[i].serialnumberofthemachinery,
                        YearOfManufacture: qrList[i].yearofmanufacturing,
                        QRNumber: "\"=\"" + "01" + String(qrList[i].qrcode),
                        MRP: qrList[i].mrp,
                        EngineNumber: qrList[i].enginenumber,
                        ChasisNumber: qrList[i].chasisnumber,
                        createdat: "\"=\"" + dateformat,
                    }
                }
                else if (groupName === 'Farm Machinery Part-C') {
                    qrObj = {
                        SerialNumber: qrList[i].serialnumber,
                        MachineSerialNumber: qrList[i].serialnumberofthemachinery,
                        YearOfManufacture: qrList[i].yearofmanufacturing,
                        QRNumber: "\"=\"" + "01" + String(qrList[i].qrcode),
                        MRP: qrList[i].mrp,
                        EngineNumber: qrList[i].enginenumber,
                        PumpNumber: qrList[i].pumpnumber,
                        EngineIsLicenceValidity: qrList[i].engineislicencevalidity,
                        PumpIsLicenceValidity: qrList[i].pumpislicencevalidity,
                        createdat: "\"=\"" + dateformat
                    }
                }
                else if (groupName === 'TARPAULINS') {
                    qrObj = {
                        SerialNumber: qrList[i].serialnumber,
                        ManufactureDate: qrList[i].manufacturedate,
                        ExpiryDate: qrList[i].expirydate,
                        ContainerQRCode: qrList[i].containerqrcode === "" ? "" : "\"=\"" + "00" + qrList[i].containerqrcode,
                        ContainerRange: containerRange,
                        BatchNumber: qrList[i].batchnumber,
                        QRNumber: "\"=\"" + "01" + String(qrList[i].qrcode),
                        createdat: "\"=\"" + dateformat,
                        isCallExtApi: qrList[i].isCallExtApi
                    }
                }
                else if (groupName === 'MICRO IRRIGATION') {
                    qrObj = {
                        SerialNumber: qrList[i].serialnumber,
                        ContainerQRCode: qrList[i].containerqrcode === "" ? "" : "\"=\"" + "00" + qrList[i].containerqrcode,
                        ContainerRange: containerRange,
                        BatchNumber: qrList[i].batchnumber,
                        QRNumber: "\"=\"" + "01" + String(qrList[i].qrcode),
                        createdat: "\"=\"" + dateformat,
                        BundleLateralSize: qrList[i].bundlelateralsize,
                        isCallExtApi: qrList[i].isCallExtApi
                    }
                }
                resArray.push(qrObj);
            }
        }
        else {
            let lastContainerRange = [];
            console.log(qrList.length);
            for (let i = 0; i < qrList.length; i++) {
                let dateformat = moment(qrList[i].createdat).tz('Asia/Kolkata')
                    .format('DD/MM/YYYY HH:mm');
                let containerRange = "";
                if (qrList[i].containerrange.length !== 0) {
                    containerRange = String("[ " + String(qrList[i].containerrange[0]) + "-" + String(qrList[i].containerrange[1]) + " ]");
                }
                if (lastContainerRange.includes(containerRange)) {
                    continue;
                }
                else {
                    lastContainerRange.push(containerRange)
                }
                let qrObj = {
                    BatchNumber: qrList[i].batchnumber,
                    ManufactureDate: qrList[i].manufacturedate,
                    ExpiryDate: qrList[i].expirydate,
                    ContainerQRCode: containerRange === "" ? "" : "\"=\"" + "00" + String(qrList[i].containerqrcode),
                    ContainerRange: containerRange,
                    createdat: "\"=\"" + dateformat,
                }
                resArray.push(qrObj);
            }
        }
        return resArray;
        
    }


    const handleCheckboxChange = (event) => {
        // console.log("Checkbox", event.target.checked)
        let newArray = [];
        let lastContainerRange = [];
        if (event.target.checked === true) {
            for (let i = 0; i < rows.length; i++) {
                let containerRange = "";
                if (rows[i].containerrange.length !== 0) {
                    containerRange = String("[ " + String(rows[i].containerrange[0]) + "-" + String(rows[i].containerrange[1]) + " ]");
                }
                if (lastContainerRange.includes(containerRange)) {
                    continue;
                }
                else {
                    lastContainerRange.push(containerRange)
                }
                newArray.push(rows[i]);
            }
            // console.log(newArray)
            setRows(newArray);
        }
        else {
            setRows(state.qrCodeArray);
        }
        setContainerEnabled(event.target.checked);
    };

    const customFormatter = {
        // Convert large numbers to strings
        numberWithCommas: (value) => {
            if (typeof value === "string") {
                return value;
            } else if (typeof value === "number") {
                return value.toString();
            } else {
                return "";
            }
        }
    };

    const generateContainerEnabledList = (qrList) => {
        let resArray = [];
        let lastContainerRange = [];
        // console.log(qrList);
        for (let i = 0; i < qrList.length; i++) {
            let dateformat = moment(qrList[i].createdat).tz('Asia/Kolkata')
                .format('DD/MM/YYYY HH:mm');
            let containerRange = "";
            if (qrList[i].ContainerRange.length !== 0) {
                containerRange = qrList[i].ContainerRange;
            }
            if (lastContainerRange.includes(containerRange)) {
                continue;
            }
            else {
                lastContainerRange.push(containerRange)
            }
            let qrObj = {
                ProductAlias: qrList[i].ProductAlias,
                BatchNumber: qrList[i].BatchNumber,
                ManufactureDate: qrList[i].ManufactureDate,
                ExpiryDate: qrList[i].ExpiryDate,
                ContainerQRCode: qrList[i].ContainerQRCode,
                ContainerRange: containerRange,
                createdat: "\"=\"" + dateformat,
            }
            resArray.push(qrObj);
        }
        return resArray;
    }


    return (
        <div>
            {pageLoading === true ? <div style={{ marginTop: '10px', textAlign: 'center' }}><b>We are working on your request. Please be patient.</b><Loading /> </div> :
                <div>
                    <UserGuard>
                        <Topbar />
                        <Button style={{ backgroundColor: "#000" }} variant="contained" onClick={handleBackButton} startIcon={<ArrowBackIcon />}>Go Back</Button>

                        <Typography variant="h4" style={{ width: '100%', margin: '20px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>Dowload PDF</Typography>

                        <div style={{ width: '100%', margin: '5px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                            {rows.length === 0 ? <></> : <div style={{ "display": "flex", "flexDirection": "row" }}>
                                <div style={{ marginLeft: "10px", marginRight: "10px", marginTop: "8px", width: "150px" }}>
                                    <TextField id="outlined-basic" label="Start SN" size="small" variant="outlined" value={startSN}
                                        onInput={handleStartSNChange} />
                                </div>
                                <div style={{ marginLeft: "10px", marginRight: "10px", marginTop: "8px", width: "150px" }}>
                                    <TextField id="outlined-basic" label="End SN" size="small" variant="outlined" value={endSN}
                                        onInput={handleEndSNChange} />
                                </div>
                                <div style={{ "margin": "10px", "marginTop": "10px" }}>
                                    <FormControl>
                                        <FormControlLabel control={
                                            <Checkbox
                                                checked={printMRP}
                                                onChange={handlePrintMRP}
                                            />
                                        } label="Print MRP" />
                                    </FormControl>
                                </div>
                                {printMRP ? <div style={{ marginLeft: "10px", marginRight: "10px", marginTop: "8px", width: "150px" }}>
                                    <TextField id="outlined-basic" label="Enter MRP" size="small" variant="outlined" inputProps={{maxLength: 13}} value={mrp}
                                        onInput={handleMRPChange} />
                                </div> : <></>}
                            </div>}
                        </div>
                        <div style={{ width: '100%', margin: '5px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                            <Button
                                style={{ "margin": "5px" }}
                                variant="contained"
                                onClick={async () => { await generatePDF(convertQRCodeArray(rows), groupName, companyName, startSN, endSN, containerEnabled, printMRP, mrp, setPageLoading, setOpen, setSnackbarMessage) }}
                            >Print QR(2x1 inch)</Button>
                            <Button
                                style={{ "margin": "5px" }}
                                variant="contained"
                                onClick={async () => { await generatev2PDF(convertQRCodeArray(rows), groupName, companyName, startSN, endSN, containerEnabled, printMRP, mrp, setPageLoading, setOpen, setSnackbarMessage) }}
                            >Print QR(A4 size)</Button>
                            <Button
                                style={{ "margin": "5px" }}
                                variant="contained"
                                onClick={async () => { await generatev3PDF(convertQRCodeArray(rows), groupName, companyName, startSN, endSN, containerEnabled, printMRP, mrp, setPageLoading, setOpen, setSnackbarMessage) }}
                            >Print QR(1x1 inch)</Button>
                            <Button
                                variant="contained" style={{ "margin": "10px", textDecoration: 'none', color: '#FFF' }}
                            ><CSVLink data={containerEnabled === true ? generateContainerEnabledList(state.excelRows) : state.excelRows} filename={"QRDetails.csv"} separator={","} formatter={customFormatter} style={{ textDecoration: 'none', color: '#FFF' }}>Download Report</CSVLink>
                            </Button>
                            {isContainer === false ? <></> :
                                <div style={{ marginLeft: '50px' }}>
                                    <FormControl>
                                        <FormControlLabel control={
                                            <Checkbox
                                                checked={containerEnabled}
                                                onChange={handleCheckboxChange}
                                            />
                                        } label="Container Enabled" />
                                    </FormControl>
                                </div>
                            }
                        </div>
                        <Typography variant="h4" style={{ width: '100%', margin: '20px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>QR Details</Typography>
                        <div>
                            {groupName ?
                                <div style={{ "marginTop": "40px" }}>
                                    <TableContainer component={Paper}>
                                        {loading === true ? <Loading /> :
                                            <Table sx={{ minWidth: 500 }} stickyHeader aria-label="sticky table">
                                                <TableHead>
                                                    <TableRow>
                                                        {!containerEnabled && <TableCell align={'right'} style={{ minWidth: 100 }}>
                                                            Product Name
                                                        </TableCell>}
                                                        <TableCell align={'right'} style={{ minWidth: 100 }}>Product Alias</TableCell>
                                                        {!containerEnabled &&
                                                            <TableCell align={'right'} style={{ minWidth: 100 }}>Serial Number</TableCell>
                                                        }
                                                        {(groupName === 'SEEDS' || groupName === 'FERTILIZER' || groupName === 'PESTICIDES' || groupName === 'CHEMICALS') &&
                                                            <TableCell align={'right'} style={{ minWidth: 100 }}>Batch Number</TableCell>
                                                        }
                                                        {(groupName === 'SEEDS' || groupName === 'FERTILIZER' || groupName === 'PESTICIDES' || groupName === 'TARPAULINS' || groupName === 'CHEMICALS') &&
                                                            <TableCell align={'right'} style={{ minWidth: 100 }}>Manufacture Date</TableCell>
                                                        }
                                                        {(groupName === 'SEEDS' || groupName === 'FERTILIZER' || groupName === 'PESTICIDES' || groupName === 'TARPAULINS' || groupName === 'CHEMICALS') &&
                                                            <TableCell align={'right'} style={{ minWidth: 100 }}>Expiry Date</TableCell>
                                                        }
                                                        {(groupName === 'SEEDS' || groupName === 'FERTILIZER' || groupName === 'PESTICIDES' || groupName === 'CHEMICALS' || groupName === 'TARPAULINS') &&
                                                            <TableCell align={'right'} style={{ minWidth: 100 }}>Container QR Code</TableCell>
                                                        }
                                                        {(groupName === 'SEEDS' || groupName === 'FERTILIZER' || groupName === 'PESTICIDES' || groupName === 'CHEMICALS' || groupName === 'TARPAULINS') &&
                                                            <TableCell align={'right'} style={{ minWidth: 100 }}>Container Range</TableCell>
                                                        }
                                                        {!containerEnabled && (groupName === 'SEEDS' || groupName === 'FERTILIZER' || groupName === 'PESTICIDES' || groupName === 'CHEMICALS' || groupName === 'TARPAULINS' || groupName === 'Farm Machinery Part-A' || groupName === 'Farm Machinery Part-B' || groupName === 'Farm Machinery Part-C') &&
                                                            <TableCell align={'right'} style={{ minWidth: 100 }}>QR Number</TableCell>
                                                        }
                                                        {(groupName === 'Farm Machinery Part-A' || groupName === 'Farm Machinery Part-B' || groupName === 'Farm Machinery Part-C') && (<>
                                                            <TableCell align={'right'} style={{ minWidth: 100 }}>Machine Serial Number</TableCell>
                                                            <TableCell align={'right'} style={{ minWidth: 100 }}>Year of Manufacturing</TableCell>
                                                            <TableCell align={'right'} style={{ minWidth: 100 }}>MRP</TableCell>
                                                        </>)
                                                        }
                                                        {groupName === 'Farm Machinery Part-B' && (<>
                                                            <TableCell align={'right'} style={{ minWidth: 100 }}>Engine Number</TableCell>
                                                            <TableCell align={'right'} style={{ minWidth: 100 }}>Chasis Number</TableCell>
                                                        </>)
                                                        }
                                                        {groupName === "Farm Machinery Part-C" && (
                                                            <>
                                                                <TableCell align={'right'} style={{ minWidth: 100 }}>
                                                                    Engine Number
                                                                </TableCell>
                                                                <TableCell align={"right"} style={{ minWidth: 100 }}>
                                                                    Pump Number
                                                                </TableCell>
                                                                <TableCell align={"right"} style={{ minWidth: 100 }}>
                                                                    Engine IS Licence Validity
                                                                </TableCell>
                                                                <TableCell align={"right"} style={{ minWidth: 100 }}>
                                                                    Pump IS Licence Validity
                                                                </TableCell>
                                                            </>
                                                        )}
                                                        {groupName === 'MICRO IRRIGATION' && (
                                                            <>
                                                                {!containerEnabled && <TableCell align={'right'} style={{ minWidth: 100 }}>QR Number</TableCell>}
                                                                <TableCell align={'right'} style={{ minWidth: 100 }}>Container QR Code</TableCell>
                                                                <TableCell align={'right'} style={{ minWidth: 100 }}>Container Range</TableCell>
                                                                <TableCell align={'right'} style={{ minWidth: 100 }}>Batch Number</TableCell>
                                                            </>
                                                        )}
                                                        <TableCell align={'right'} style={{ minWidth: 100 }}>Created Date</TableCell>
                                                        {filter === 'Date Filter' ?
                                                            <TableCell align={'right'} style={{ minWidth: 100 }}>Active QR</TableCell> : <></>
                                                        }

                                                    </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                    {(rowsPerPage > 0
                                                        ? rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                                        : rows
                                                    ).map((row, i) => (
                                                        <TableRow key={i}>
                                                            {!containerEnabled && <TableCell style={{ width: 160 }} align="center">
                                                                {product.productname ? String(product.productname) : ""}
                                                            </TableCell>}
                                                            <TableCell style={{ width: 160 }} align="right">
                                                                {product.productalias ? String(product.productalias) : ""}
                                                            </TableCell>
                                                            {!containerEnabled &&
                                                                <TableCell style={{ width: 160 }} align="right">
                                                                    {row.serialnumber ? row.serialnumber : ""}
                                                                </TableCell>}
                                                            {(groupName === 'SEEDS' || groupName === 'FERTILIZER' || groupName === 'PESTICIDES' || groupName === 'CHEMICALS') &&
                                                                <TableCell align={'right'} style={{ minWidth: 100 }}> {row.batchnumber ? String(row.batchnumber) : ""}</TableCell>
                                                            }

                                                            {(groupName === 'SEEDS' || groupName === 'FERTILIZER' || groupName === 'PESTICIDES' || groupName === 'TARPAULINS' || groupName === 'CHEMICALS') &&
                                                                <TableCell align={'right'} style={{ minWidth: 100 }}>{row.manufacturedate ? String(row.manufacturedate) : ""}</TableCell>
                                                            }
                                                            {(groupName === 'SEEDS' || groupName === 'FERTILIZER' || groupName === 'PESTICIDES' || groupName === 'TARPAULINS' || groupName === 'CHEMICALS') &&
                                                                <TableCell align={'right'} style={{ minWidth: 100 }}>{row.expirydate ? String(row.expirydate) : ""}</TableCell>
                                                            }
                                                            {(groupName === 'SEEDS' || groupName === 'FERTILIZER' || groupName === 'PESTICIDES' || groupName === 'CHEMICALS' || groupName === 'TARPAULINS') &&
                                                                <TableCell align={'right'} style={{ minWidth: 100 }}>{row.containerqrcode ? "00" + String(row.containerqrcode) : ""}</TableCell>
                                                            }
                                                            {(groupName === 'SEEDS' || groupName === 'FERTILIZER' || groupName === 'PESTICIDES' || groupName === 'CHEMICALS' || groupName === 'TARPAULINS') &&
                                                                <TableCell align={'right'} style={{ minWidth: 100 }}>{row.containerrange ? String(row.containerrange) : ""}</TableCell>
                                                            }
                                                            {!containerEnabled && (groupName === 'SEEDS' || groupName === 'FERTILIZER' || groupName === 'PESTICIDES' || groupName === 'CHEMICALS' || groupName === 'TARPAULINS' || groupName === 'Farm Machinery Part-A' || groupName === 'Farm Machinery Part-B' || groupName === 'Farm Machinery Part-C') &&
                                                                <TableCell align={'right'} style={{ minWidth: 100 }}>{row.qrcode ? "01" + String(row.qrcode) : ""}</TableCell>
                                                            }
                                                            {(groupName === 'Farm Machinery Part-A' || groupName === 'Farm Machinery Part-B' || groupName === 'Farm Machinery Part-C') && (<>
                                                                <TableCell align={'right'} style={{ minWidth: 100 }}>{row.serialnumberofthemachinery ? String(row.serialnumberofthemachinery) : ""}</TableCell>
                                                                <TableCell align={'right'} style={{ minWidth: 100 }}>{row.yearofmanufacturing ? String(row.yearofmanufacturing) : ""}</TableCell>
                                                                <TableCell align={'right'} style={{ minWidth: 100 }}>{row.MRP ? String(row.MRP) : ""}</TableCell>
                                                            </>)
                                                            }
                                                            {groupName === "Farm Machinery Part-B" && (
                                                                <>
                                                                    <TableCell align={"right"} style={{ minWidth: 100 }}>
                                                                        {row.enginenumber ? String(row.enginenumber) : ""}
                                                                    </TableCell>
                                                                    <TableCell align={"right"} style={{ minWidth: 100 }}>
                                                                        {row.chasisnumber ? String(row.chasisnumber) : ""}
                                                                    </TableCell>
                                                                </>
                                                            )}

                                                            {groupName === "Farm Machinery Part-C" && (
                                                                <>
                                                                    <TableCell align={"right"} style={{ minWidth: 100 }}>
                                                                        {row.EngineNumber ? String(row.enginenumber) : ""}
                                                                    </TableCell>
                                                                    <TableCell align={"right"} style={{ minWidth: 100 }}>
                                                                        {row.pumpnumber ? String(row.pumpnumber) : ""}
                                                                    </TableCell>
                                                                    <TableCell align={"right"} style={{ minWidth: 100 }}>
                                                                        {row.engineislicencevalidity ? String(row.engineislicencevalidity) : ""}
                                                                    </TableCell>
                                                                    <TableCell align={"right"} style={{ minWidth: 100 }}>
                                                                        {row.pumpislicencevalidity ? String(row.pumpislicencevalidity) : ""}
                                                                    </TableCell>
                                                                </>
                                                            )}
                                                            {groupName === "MICRO IRRIGATION" && (
                                                                <>
                                                                    {!containerEnabled && <TableCell align={'right'} style={{ minWidth: 100 }}>{row.qrcode ? "01" + String(row.qrcode) : ""}</TableCell>}
                                                                    <TableCell align={'right'} style={{ minWidth: 100 }}>      {row.containerqrcode ? "00" + String(row.containerqrcode) : ""}</TableCell>
                                                                    <TableCell align={'right'} style={{ minWidth: 100 }}>{row.containerrange ? String(row.containerrange) : ""}</TableCell>
                                                                    <TableCell align={"right"} style={{ minWidth: 100 }}>
                                                                        {row.batchnumber ? String(row.batchnumber) : ""}
                                                                    </TableCell>

                                                                </>
                                                            )}
                                                            <TableCell style={{ width: 160 }} align="right">
                                                                {row.createdat ? String(moment(row.createdat).tz('Asia/Kolkata').format('DD/MM/YYYY HH:mm')) : ""}
                                                            </TableCell>
                                                        </TableRow>
                                                    ))}

                                                    {emptyRows > 0 && (
                                                        <TableRow style={{ height: 53 * emptyRows }}>
                                                            <TableCell colSpan={6} />
                                                        </TableRow>
                                                    )}
                                                </TableBody>
                                                <TableFooter>
                                                    <TableRow>
                                                        <TablePagination
                                                            rowsPerPageOptions={[5, 10, 25, { label: 'All', value: -1 }]}
                                                            colSpan={3}
                                                            count={rows.length}
                                                            rowsPerPage={rowsPerPage}
                                                            page={page}
                                                            SelectProps={{
                                                                inputProps: {
                                                                    'aria-label': 'rows per page',
                                                                },
                                                                native: true,
                                                            }}
                                                            onPageChange={handleChangePage}
                                                            onRowsPerPageChange={handleChangeRowsPerPage}
                                                            ActionsComponent={TablePaginationActions}
                                                        />
                                                    </TableRow>
                                                </TableFooter>
                                            </Table>
                                        }
                                    </TableContainer>
                                </div>
                                : <></>}

                            <div>
                            </div>
                        </div>
                    </UserGuard>
                </div>
            }
        </div>
    )
}

export default GenerateQRPDF;