import React from "react";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import Topbar from "../components/Topbar";
import PropTypes from 'prop-types';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import FirstPageIcon from '@mui/icons-material/FirstPage';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import LastPageIcon from '@mui/icons-material/LastPage';
import Button from '@mui/material/Button';
import FormControl from '@mui/material/FormControl';
import AdminAuth from "../hooks/AdminAuth";
import { CSVLink } from 'react-csv'
import UserGuard from "../hooks/UserGuard";
import axios from 'axios'
import Skeleton from '@mui/material/Skeleton';
import Loading from "../components/Loading";

import Snackbar from '@mui/material/Snackbar';
import CloseIcon from '@mui/icons-material/Close';
import useAuth from "../hooks/useAuth";
import { Autocomplete, TextField } from "@mui/material";


// 1. DATE OF QR CODE
// 2. INDIVIDUAL QR CODE
// 3. CONTAINER QR CODE
// 4. ProductName
// 5. BatchNumber
// 6. ManufactureDate
// 7. ExpiryDate


function TablePaginationActions(props) {
  const theme = useTheme();
  const { count, page, rowsPerPage, onPageChange } = props;

  const handleFirstPageButtonClick = (event) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <Box sx={{ flexShrink: 0, ml: 2.5 }}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </Box>
  );
}

TablePaginationActions.propTypes = {
  count: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
};


const ProductReport = () => {
  // States
  const [fromDate, setFromDate] = React.useState('');
  const [toDate, setToDate] = React.useState('');
  const [companyName, setCompanyName] = React.useState("");
  const [reqData, setReqData] = React.useState({});
  const [rows, setRows] = React.useState([]);
  const [loading, setLoading] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const [snackbarMessage, setSnackbarMessage] = React.useState("");
  const {  companyList, setCompanyList } = useAuth();

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
  };

  const action = (
    <React.Fragment>
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={handleClose}
      >
        <CloseIcon fontSize="small" />
      </IconButton>
    </React.Fragment>
  );

 

  React.useEffect(() => {
    async function sendRequest() {
      const token = localStorage.getItem('token');
      // console.log("inside sendReqeust")
      const configs = {
        headers: { 'Authorization': `Bearer ${token}` }
      }
      try {
        const res = await axios.post('/api/admin/getProductReport', reqData, configs);
        // console.log(res.data);
        const productList = res.data;
  
        // console.log(productList)
        setRows(productList);
        setOpen(true)
        setSnackbarMessage("Report generated successfully")
      }
      catch (error) {
        // console.log(error)
        if (error.response) {
          setOpen(true)
          setSnackbarMessage(error.response.data.message)
        }
        else {
          setOpen(true);
          setSnackbarMessage(error.message)
        }
      }
      setLoading(false)
    }
    // console.log(reqData, Object.keys(reqData).length, rows)
    if (reqData.length !== 0 && Object.keys(reqData).length !== 0) {
      setLoading(true)
      sendRequest();
    }
  }, [reqData])


  React.useEffect(() => {
    async function getCompanyList() {
      const token = localStorage.getItem('token');
      const configs = {
        headers: { 'Authorization': `Bearer ${token}` }
      }
      const res = await axios.get('/api/admin/getCompanyList', configs);
      // console.log(res)
      setCompanyList(res.data);
    }
    if (companyList.length === 0)
      getCompanyList()
  }, [])


  // const emptyRows =
  // page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

  // const handleChangePage = (event, newPage) => {
  //     setPage(newPage);
  // };

  // const handleChangeRowsPerPage = (event) => {
  //     setRowsPerPage(parseInt(event.target.value, 10));
  //     setPage(0);
  // };

  const handleCompanyChange = (event, company) => {
    // console.log(company);
    if (company !== null) {
      setCompanyName(company);
    }
  };

  const handleSubmit = () => {
    if (fromDate === "") {
      setOpen(true);
      setSnackbarMessage("Please enter fromDate");
    }
    else if (toDate === "") {
      setOpen(true);
      setSnackbarMessage("Please enter to Date");
    }
    else if (companyName === "") {
      setOpen(true);
      setSnackbarMessage("please select a company name")
    }
    else {
      const reqPayload = {
        "fromDate": fromDate.format('YYYY-MM-DD'),
        "toDate": toDate.format('YYYY-MM-DD'),
        "company": companyName
      }
      setReqData(reqPayload);
    }
  }

  return (
    <div>
      {open === true ? <Snackbar
        open={open}
        autoHideDuration={3000}
        onClose={handleClose}
        message={snackbarMessage}
        action={action}
      /> : <></>}
      <UserGuard>
        <AdminAuth>
          <Topbar />
          <div style={{ "marginTop": "20px", "display": "flex" }}>
            <div style={{ "margin": "10px", "width": "250px" }}>
              {companyList.length === 0 ?
                <Skeleton variant="rounded" fullWidth height={60} /> :

                <FormControl fullWidth required>
                  <Autocomplete
                    id="combo-box-demo"
                    options={companyList}
                    value={companyName}
                    onChange={handleCompanyChange}
                    renderInput={(params) => (
                      <TextField
                        required
                        {...params}
                        label="Select Your Company"
                      />
                    )}
                  />
                </FormControl>
              }
            </div>
            <div style={{ "margin": "10px" }}>
              <LocalizationProvider required dateAdapter={AdapterDayjs}>
                <DatePicker
                  label="from date"
                  value={fromDate}
                  onChange={(newValue) => setFromDate(newValue)}
                  format="DD/MM/YYYY"
                />
              </LocalizationProvider>
            </div>
            <div style={{ "margin": "10px" }}>
              <LocalizationProvider required dateAdapter={AdapterDayjs}>
                <DatePicker
                  label="to date"
                  value={toDate}
                  onChange={(newValue) => setToDate(newValue)}
                  required
                  format="DD/MM/YYYY HH:mm"
                />
              </LocalizationProvider>
            </div>
            <div style={{ "margin": "10px", "marginTop": "20px" }}>
              <Button
                variant="contained"
                onClick={handleSubmit}
              >Submit</Button>
            </div>
            {rows.length === 0 ? <></> : <div style={{ "margin": "10px", "marginTop": "20px" }}>
              <Button
                variant="contained" style={{ textDecoration: 'none', color: '#FFF' }}
              ><CSVLink data={rows} filename={"product-report.csv"} style={{ textDecoration: 'none', color: '#FFF' }}>Download Report</CSVLink></Button>
            </div>}
          </div>
          {loading === true ? <Loading /> : <> </> }
             
           {/* {rows.length === 0 ? <></>:
                  <div style={{"marginTop":"40px"}}>
                    <TableContainer component={Paper}>
                        <Table sx={{ minWidth: 500 }} stickyHeader aria-label="sticky table">
                            <TableHead>
                                <TableRow>
                                {columns.map((column) => (
                                    <TableCell
                                    key={column.id}
                                    align={column.align}
                                    style={{ minWidth: column.minWidth }}
                                    >
                                    {column.label}
                                    </TableCell>
                                ))}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                            {(rowsPerPage > 0
                                ? rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                : rows
                            ).map((row) => (
                                <TableRow key={row.name}>
                                <TableCell component="th" scope="row">
                                    {String(row.name)}
                                </TableCell>
                                <TableCell style={{ width: 10 }} align="right">
                                    {String(row.code)}
                                </TableCell>
                                <TableCell style={{ width: 160 }} align="right">
                                    {String(row.population)}
                                </TableCell>
                                <TableCell style={{ width: 160 }} align="right">
                                    {String(row.size)}
                                </TableCell>
                                <TableCell style={{ width: 160 }} align="right">
                                    {String(row.density)}
                                </TableCell>
                                </TableRow>
                            ))}

                            {emptyRows > 0 && (
                                <TableRow style={{ height: 53 * emptyRows }}>
                                <TableCell colSpan={6} />
                                </TableRow>
                            )}
                            </TableBody>
                            <TableFooter>
                            <TableRow>
                                <TablePagination
                                rowsPerPageOptions={[5, 10, 25, { label: 'All', value: -1 }]}
                                colSpan={3}
                                count={rows.length}
                                rowsPerPage={rowsPerPage}
                                page={page}
                                SelectProps={{
                                    inputProps: {
                                    'aria-label': 'rows per page',
                                    },
                                    native: true,
                                }}
                                onPageChange={handleChangePage}
                                onRowsPerPageChange={handleChangeRowsPerPage}
                                ActionsComponent={TablePaginationActions}
                                />
                            </TableRow>
                            </TableFooter>
                        </Table>
                    </TableContainer>
                </div>
              }  */}

        </AdminAuth>
      </UserGuard>
    </div>
  )
}

export default ProductReport;
