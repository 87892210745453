import React, { useRef } from 'react';
import { jsPDF } from 'jspdf';
import QRCode from 'qrcode';
import { useLocation } from 'react-router';

const QRCodePrintComponent = () => {
    const {state} = useLocation();
    const qrCodes = state;
    
      const generatePDF = () => {
        const qrCodeWidth = 25; // QR code width in mm
        const qrCodeHeight = 25; // QR code height in mm
        const pageWidth = 150; // Page width in mm
        const pageHeight = 30; // Page height in mm
        const textMargin = 3;
        const fontSize = 10; // Font size for the QR code number


        const doc = new jsPDF({
          orientation: 'landscape',
          unit: 'mm',
          format: [pageWidth, pageHeight],
        });
    
        let x = 0; // Horizontal position
        let y = 0; // Vertical position
    
        for (let i = 0; i < qrCodes.length; i++) {
          const code = qrCodes[i];
    
          if (i % 2 === 0 && i !== 0) {
            doc.addPage();
            x = 0;
            y = 0;
          }
    
          const qrCodeData = code.QRNumber.slice(3);
    
          // Increase canvas resolution
          const canvas = document.createElement('canvas');
          const scale = window.devicePixelRatio || 1;
          canvas.width = qrCodeWidth * scale;
          canvas.height = qrCodeHeight * scale;
          canvas.style.width = qrCodeWidth + 'mm';
          canvas.style.height = qrCodeHeight + 'mm';
          const context = canvas.getContext('2d');
          context.scale(scale, scale);
    
          // Render QR code component to the canvas
          QRCode.toCanvas(canvas, qrCodeData, { width: qrCodeWidth * scale, height: qrCodeHeight * scale });
    
          // Convert canvas to base64 image
          const qrCodeImage = canvas.toDataURL('image/png');
    
          // Render QR code image to the PDF
          doc.addImage(qrCodeImage, 'PNG', x, y, qrCodeWidth, qrCodeHeight);
          
          doc.setFontSize(fontSize);
          doc.text(qrCodeData, x + qrCodeWidth + textMargin, y + qrCodeHeight / 2, {
            baseline: 'middle',
          });

          if (i % 2 === 0) {
            x += qrCodeWidth*3;
          } else {
            x = 0;
            y += qrCodeHeight;
          }
        }
    
        doc.save('qrcodes.pdf');
      };
    
      return (
        <div>
          <button onClick={generatePDF}>Download PDF</button>
        </div>
      );
};


export default QRCodePrintComponent;