import { Navigate } from "react-router-dom";
import useAuth from "./useAuth";

const UserGuard = ({children}) => {
    const { isAuthenticated } = useAuth();

    if(!isAuthenticated){
        return <Navigate to="/login" />;
    }
    else{
        return <>{children}</>
    }
}

export default UserGuard;