import React from 'react';
import { createRoot } from 'react-dom/client';
import App from './App';
import "./index.css"
import { AuthProvider } from './components/AuthContext';

const container = document.getElementById('root');
const root = createRoot(container);

if (process.env.NODE_ENV !== "development") {
      console.log = () => {};
      console.debug = () => {};
      console.info = () => {};
      console.warn = () => {};
}

root.render(
      <AuthProvider>
            <App />
      </AuthProvider>
      
      
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
