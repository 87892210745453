import React from "react";
import Topbar from "../components/Topbar";
import UserGuard from "../hooks/UserGuard";
import AdminAuth from "../hooks/AdminAuth";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import useAuth from "../hooks/useAuth";
import axios from "axios";
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';

import IconButton from '@mui/material/IconButton';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import InputAdornment from '@mui/material/InputAdornment';
import FormControl from '@mui/material/FormControl';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import Loading from '../components/Loading';

import Snackbar from '@mui/material/Snackbar';
import CloseIcon from '@mui/icons-material/Close';


import Autocomplete from '@mui/material/Autocomplete';

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Skeleton from '@mui/material/Skeleton';
import Grid from '@mui/material/Grid';
import UserAuth from './../hooks/UserAuth';
import { Container, Typography } from "@mui/material";
import home_bg from '../assets/home_bg.jpg'


const Home = () => {
    const [unverifiedUserList, setUnverifiedUserList] = React.useState([]);
    const [reqData, setReqData] = React.useState({});
    const [resetList, setResetList] = React.useState(false);
    const [reqPassData, setReqPassData] = React.useState({});
    const [showPassword, setShowPassword] = React.useState(false);
    const [password, setPassword] = React.useState("");
    const [passwordtwo, setPasswordtwo] = React.useState("");
    const [loading, setLoading] = React.useState(true);
    const [open, setOpen] = React.useState(false);
    const [delReqData, setDelReqData] = React.useState({})
    const [companyName, setCompanyName] = React.useState("");
    const { currentUser, setCompanyList } = useAuth();
    const [companyDetails, setCompanyDetails] = React.useState([])
    const [openDialog, setOpenDialog] = React.useState(false);
    const [companyDetail, setCompanyDetail] = React.useState({});
    const [userEditData, setUserEditData] = React.useState({});
    const [snackbarMessage, setSnackbarMessage] = React.useState("");
    const formRef = React.useRef(null);



    React.useEffect(() => {
        async function getCompanyDetails() {
            const token = localStorage.getItem('token');
            const configs = {
                headers: { 'Authorization': `Bearer ${token}` }
            }
            try {
                const res = await axios.get('/api/admin/getCompanyDetails', configs);
                // console.log(res)
                setCompanyDetails(res.data);
            }
            catch (error) {
                // console.log(error)
                if (error.response) {
                    setOpen(true)
                    setSnackbarMessage(error.response.data.message)
                }
                else {
                    setOpen(true);
                    setSnackbarMessage(error.message)
                }
            }
        }
        if (currentUser.userrole === 'ADMIN')
            getCompanyDetails()
    }, [])

    React.useEffect(() => {
        if (currentUser.userrole === 'ADMIN') {
            const token = localStorage.getItem('token');
            async function unverifiedUserList() {
                const config = {
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${token}`
                    }
                };
                await axios.get('/api/admin/unverifiedUserList', config)
                    .then(res => {
                        // console.log(res.data);
                        setUnverifiedUserList(res.data);
                    })
                    .catch(error => {
                        // console.log(error)
                        if (error.response) {
                            setOpen(true)
                            setSnackbarMessage(error.response.data.message)
                        }
                        else {
                            setOpen(true);
                            setSnackbarMessage(error.message)
                        }
                    })
                setLoading(false)
            }
            unverifiedUserList();
        }
    }, [])

    React.useEffect(() => {
        // console.log("resetList", resetList)
        if (currentUser.userrole === 'ADMIN' && resetList === true) {
            const token = localStorage.getItem('token');
            // console.log(token, currentUser)
            async function unverifiedUserList() {
                const config = {
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${token}`
                    }
                };
                await axios.get('/api/admin/unverifiedUserList', config)
                    .then(res => {
                        // console.log(res.data);
                        setUnverifiedUserList(res.data);
                    })
                    .catch(error => {
                        // console.log(error)
                        if (error.response) {
                            setOpen(true)
                            setSnackbarMessage(error.response.data.message)
                        }
                        else {
                            setOpen(true);
                            setSnackbarMessage(error.message)
                        }
                    })
            }
            unverifiedUserList()
            setResetList(false)
        }
    }, [resetList])

    React.useEffect(() => {
        if (Object.keys(reqData).length === 2) {
            const token = localStorage.getItem('token');
            // console.log(token, currentUser)
            async function verifyUser() {
                const config = {
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${token}`
                    }
                };
                try {
                    const res = await axios.post(`/api/admin/verify/${reqData.userEmail}`, reqData, config)
                    if (res.status === 200) {
                        setOpen(true);
                        setSnackbarMessage("User verified successfully")
                        setResetList(true);
                        // console.log(res.data);
                    }
                    else {
                        setOpen(true);
                        setSnackbarMessage("Unknown error occured")
                        // console.log(res)                        
                    }
                }
                catch (error) {
                    if (error.response) {
                        setOpen(true)
                        setSnackbarMessage(error.response.data.message)
                    }
                    else {
                        setOpen(true);
                        setSnackbarMessage(error.message)
                    }
                }
                getCompanyList()
            }

            async function getCompanyList() {
                const token = localStorage.getItem('token');
                const configs = {
                    headers: { 'Authorization': `Bearer ${token}` }
                }
                try {
                    const res = await axios.get('/api/admin/getCompanyList', configs);
                    if (res.status === 200) {
                        // console.log(res)
                        setCompanyList(res.data);
                    }
                    else {
                        setOpen(true);
                        setSnackbarMessage("Unknown error occurred")
                    }
                }
                catch (error) {
                    // console.log(error)
                    if (error.response) {
                        setOpen(true)
                        setSnackbarMessage(error.response.data.message)
                    }
                    else {
                        setOpen(true);
                        setSnackbarMessage(error.message)
                    }
                }
            }

            verifyUser()
            setReqData({})
        }
    }, [reqData])

    React.useEffect(() => {
        if (Object.keys(delReqData).length === 2) {
            const token = localStorage.getItem('token');
            // console.log(token, currentUser)
            async function deleteUser() {
                const config = {
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${token}`
                    }
                };
                try {
                    const res = await axios.post(`/api/admin/deleteUser/${delReqData.company}`, delReqData, config)
                    if (res.status === 200) {
                        // console.log("inside response")
                        setOpen(true);
                        setSnackbarMessage("User deleted successfully");
                        setResetList(true);
                        // console.log(res.data);
                    }
                    else {
                        setOpen(true);
                        setSnackbarMessage("Unknown error occured");
                        // console.log(res)
                    }
                }
                catch (error) {
                    if (error.response) {
                        setOpen(true)
                        setSnackbarMessage(error.response.data.message)
                    }
                    else {
                        setOpen(true);
                        setSnackbarMessage(error.message)
                    }
                }
            }
            deleteUser()
            setDelReqData({})
        }
    }, [delReqData])

    React.useEffect(() => {
        if (Object.keys(reqPassData).length === 3) {
            const token = localStorage.getItem('token');
            // console.log(token, currentUser)
            async function updatePassword() {
                const config = {
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${token}`
                    }
                };
                try {
                    const res = await axios.post(`/api/admin/changePassword`, reqPassData, config)
                    if (res.status === 200) {
                        setOpen(true);
                        setSnackbarMessage("Password changed successfully")
                    }
                    else {
                        setOpen(true);
                        setSnackbarMessage("Unknown error occured");
                    }
                }
                catch (error) {
                    if (error.response) {
                        setOpen(true)
                        setSnackbarMessage(error.response.data.message)
                    }
                    else {
                        setOpen(true);
                        setSnackbarMessage(error.message)
                    }
                }
            }
            if (Object.keys(reqPassData).length !== 0)
                updatePassword()
            setReqPassData({})
        }
    }, [reqPassData])

    React.useEffect(() => {
        const token = localStorage.getItem('token');
        // console.log(token, currentUser, userEditData);
        async function updateUserDetails() {
            const config = {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                }
            };
            try {
                const res = await axios.post('/api/admin/updateUserDetails', userEditData, config)
                // console.log("inside response", res)
                if (res.status === 200) {
                    setOpen(true)
                    setSnackbarMessage("User information updated successfully")
                    setResetList(true);
                    // console.log(res.data);
                }
                else {
                    setOpen(true)
                    setSnackbarMessage("Unknown error occurred")
                }
            }
            catch (error) {
                if (error.response) {
                    setOpen(true)
                    setSnackbarMessage(error.response.data.message)
                }
                else {
                    setOpen(true);
                    setSnackbarMessage(error.message)
                }
            }
        }
        if (Object.keys(userEditData).length !== 0)
            updateUserDetails()
        // console.log(openDialog)
    }, [userEditData])


    const handleDialogClose = () => {
        setOpenDialog(false);
    };

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpen(false);
    };

    const action = (
        <React.Fragment>
            <IconButton
                size="small"
                aria-label="close"
                color="inherit"
                onClick={handleClose}
            >
                <CloseIcon fontSize="small" />
            </IconButton>
        </React.Fragment>
    );

    const handleClickShowPassword = () => setShowPassword((show) => !show);


    const handlePassChange = (event) => {
        setPassword(event.target.value)
    }

    const handlePasstwoChange = (event) => {
        setPasswordtwo(event.target.value)
    }

    const handleSubmit = (event) => {
        event.preventDefault();
        const data = new FormData(formRef.current);
        // console.log(companyDetail)
        const reqData = {
            "id": companyDetail._id,
            "firstname": data.get('firstName'),
            "lastname": data.get('lastName'),
            "mobile": data.get('mobileNo'),
            "email": data.get('emailUser'),
            "company": data.get('company'),
            "address": data.get('address'),
            "gstno": data.get('gstno'),
        }
        // console.log(reqData)
        setUserEditData(reqData);
        setOpenDialog(false);
    };

    const handleApproval = (email, e) => {
        e.preventDefault()
        setReqData({
            "userEmail": email,
            "adminEmail": currentUser.email
        })
    }

    const handleDeleteUser = (company, e) => {
        e.preventDefault()
        setDelReqData({
            "company": company,
            "adminEmail": currentUser.email
        })
    }

    const handleEditUser = () => {
        // console.log(companyName)
        for (let i = 0; i < companyDetails.length; i++) {
            if (companyDetails[i].company === companyName) {
                setCompanyDetail(companyDetails[i]);
                break;
            }
        }
        setOpenDialog(true);
    }

    const handlePassChangeSubmit = (event) => {
        event.preventDefault()
        const data = new FormData(event.currentTarget);
        if (password !== passwordtwo) {
            setOpen(true);
            setSnackbarMessage("Password Mismatched");
        }
        else {
            const reqPassPayload = {
                "email": data.get('email'),
                "password": password,
                "adminEmail": currentUser.email
            }
            setReqPassData(reqPassPayload)
        }
    }

    return (
        <div>{open === true ? <Snackbar
            open={open}
            autoHideDuration={3000}
            onClose={handleClose}
            message={snackbarMessage}
            action={action}
        /> : <></>}
            <UserGuard>
                <Topbar />
                <UserAuth>
                    <Container>
                        <Typography variant="h4" align="center" mt={5} gutterBottom style={{ fontFamily: 'Arial', fontWeight: 'bold' }}>
                            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center' }}> <h4 style={{ marginRight: '15px' }}>WELCOME </h4>  <h3 style={{ color: '#09DF' }}> {currentUser.company} </h3> </div>
                        </Typography>
                        <Box mt={5}>
                            <Grid container spacing={5} alignItems="center" justifyContent="center">
                                <Grid item xs={12} md={6} >
                                    <img src={home_bg} alt="Home Layout" style={{ width: '100%', height: 'auto', borderRadius: '8px' }} />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <Box textAlign="center" sx={{ backgroundColor: '#f5f5f5', padding: '24px', borderRadius: '8px' }}>
                                        <Typography variant="h5" gutterBottom style={{ fontFamily: 'Arial', fontWeight: 'bold' }}>
                                            Maili's QR Code Solutions
                                        </Typography>
                                        <Typography variant="body1" style={{ fontFamily: 'Arial' }}>
                                            We are providing a solution that will cover the entire QR code requirement of KSDA using our customized web application. The application will be a Cloud-Based QR Code solution that connects a central backend system via K-KISAN APIs, which in turn handles the generation of respective products OR Codes.
                                        </Typography>
                                        <br />
                                        <Typography variant="h5" gutterBottom style={{ fontFamily: 'Arial', fontWeight: 'bold' }}>
                                            Contact Us:
                                        </Typography>
                                        <Typography variant="body1" style={{ fontFamily: 'Arial' }}>
                                            ASHOK MAILI
                                            <br />
                                            MAILI ENTERPRISES
                                            <br />
                                            Building No.2045/A, 2nd Floor, 1st Main Road, RPC Layout, Vijaynagar Club Road,
                                            <br />
                                            Vijaynagar, Bangalore - 560040
                                            <br />
                                            Mob: 9481777928 / 8970700867
                                            <br />
                                            e-mail: mailienterprise@gmail.com
                                        </Typography>

                                    </Box>
                                </Grid>
                            </Grid>
                        </Box>
                    </Container>


                </UserAuth>
                <AdminAuth>
                    <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-around' }}>
                        <div style={{ padding: '10px', boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)', textAlign: 'center' }}>
                            <Typography variant="h6">Change Password</Typography>
                            <Box component="form" onSubmit={handlePassChangeSubmit} sx={{ mt: 1 }} style={{ display: "flex", flexDirection: 'column' }}>
                                <TextField
                                    style={{ "margin": "10px" }}
                                    margin="normal"
                                    required
                                    id="email"
                                    label="Email Address"
                                    name="email"
                                />
                                <div style={{ display: 'flex' }}>
                                    <div style={{ "margin": "10px" }}>
                                        <FormControl variant="outlined">
                                            <InputLabel htmlFor="outlined-adornment-password">Password</InputLabel>
                                            <OutlinedInput
                                                id="password"
                                                type={showPassword ? 'text' : 'password'}
                                                endAdornment={
                                                    <InputAdornment position="end">
                                                        <IconButton
                                                            aria-label="toggle password visibility"
                                                            onClick={handleClickShowPassword}
                                                            edge="end"
                                                        >
                                                            {showPassword ? <VisibilityOff /> : <Visibility />}
                                                        </IconButton>
                                                    </InputAdornment>
                                                }
                                                label="Password"
                                                onInput={handlePassChange}
                                            />
                                        </FormControl>
                                    </div>
                                    <div style={{ "margin": "10px" }}>
                                        <FormControl variant="outlined">
                                            <InputLabel htmlFor="outlined-adornment-password">Confirm Password</InputLabel>
                                            <OutlinedInput
                                                id="password2"
                                                type={showPassword ? 'text' : 'password'}
                                                endAdornment={
                                                    <InputAdornment position="end">
                                                        <IconButton
                                                            aria-label="toggle password visibility"
                                                            onClick={handleClickShowPassword}
                                                            edge="end"
                                                        >
                                                            {showPassword ? <VisibilityOff /> : <Visibility />}
                                                        </IconButton>
                                                    </InputAdornment>
                                                }
                                                label="Password"
                                                onInput={handlePasstwoChange}
                                            />
                                        </FormControl>
                                    </div>
                                </div>
                                <Button
                                    style={{ "margin": "10px" }}
                                    type="submit"
                                    variant="contained"
                                    sx={{ mt: 3, mb: 2 }}
                                >
                                    Change Password
                                </Button>
                            </Box>
                        </div>
                        <div style={{ padding: '10px', boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)', textAlign: 'center' }}>
                            <Typography variant="h6">Edit User</Typography>
                            <div style={{ "margin": "10px", display: "flex", flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                                {companyDetails.length === 0 ?
                                    <Skeleton variant="rounded" fullWidth height={60} /> :
                                    <Autocomplete
                                        disablePortal
                                        id="combo-box-demo"
                                        options={companyDetails.map(company => company.company)}
                                        sx={{ width: 300 }}
                                        renderInput={(params) => <TextField {...params} label="Company Name" />}
                                        value={companyName}
                                        onChange={(event, newValue) => {
                                            setCompanyName(newValue);
                                        }}
                                    />
                                }
                                <Button
                                    style={{ "margin": "10px", width: "200px" }}
                                    variant="contained"
                                    sx={{ mt: 3, mb: 2 }}
                                    onClick={handleEditUser}
                                >
                                    Edit Details
                                </Button>
                            </div>
                        </div>
                    </div>


                    <Dialog
                        open={openDialog}
                        onClose={handleDialogClose}
                        aria-describedby="alert-dialog-slide-description"
                    >
                        <DialogTitle>{"Edit Company Details"}</DialogTitle>
                        <form onSubmit={handleSubmit} ref={formRef} sx={{ mt: 3 }}>
                            <DialogContent>
                                <Grid container spacing={2}>
                                    <Grid item xs={12} sm={6}>
                                        <TextField
                                            name="firstName"
                                            required
                                            fullWidth
                                            id="firstName"
                                            label="First Name"
                                            defaultValue={companyDetail.firstname}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <TextField
                                            required
                                            fullWidth
                                            id="lastName"
                                            label="Last Name"
                                            name="lastName"
                                            defaultValue={companyDetail.lastname}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <TextField
                                            required
                                            fullWidth
                                            id="emailUser"
                                            label="Email Address"
                                            name="emailUser"
                                            defaultValue={companyDetail.email}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <TextField
                                            required
                                            fullWidth
                                            name="mobileNo"
                                            label="Mobile Number"
                                            id="mobileNo"
                                            defaultValue={companyDetail.mobile}
                                        />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <TextField
                                            required
                                            fullWidth
                                            name="gstno"
                                            label="GST Number"
                                            id="gstno"
                                            defaultValue={companyDetail.gstno}
                                        />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <TextField
                                            required
                                            fullWidth
                                            name="address"
                                            label="Address"
                                            id="address"
                                            defaultValue={companyDetail.address}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <TextField
                                            required
                                            fullWidth
                                            name="company"
                                            label="Company Name"
                                            id="company"
                                            defaultValue={companyDetail.company}
                                        />
                                    </Grid>
                                </Grid>
                            </DialogContent>
                            <DialogActions>
                                <Button
                                    type="button"
                                    fullWidth
                                    variant="contained"
                                    sx={{ mt: 3, mb: 2 }}
                                    onClick={handleSubmit}
                                >
                                    Submit Details
                                </Button>
                            </DialogActions>
                        </form>
                    </Dialog>
                    <div style={{ boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)', textAlign: 'center', margin: '10px' }}>
                        <Typography variant="h6">User Approvals</Typography>
                        <TableContainer component={Paper} style={{ boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)' }}>
                            {loading === true ? <Loading /> :
                                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>Name</TableCell>
                                            <TableCell align="right">mobile</TableCell>
                                            <TableCell align="right">companyName</TableCell>
                                            <TableCell align="right">email</TableCell>
                                            <TableCell align="right">GST No</TableCell>
                                            <TableCell align="right">address</TableCell>
                                            <TableCell align="right">Verify</TableCell>
                                            <TableCell align="right">Delete</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {unverifiedUserList.map((user) =>
                                            <TableRow key={user.email}>
                                                <TableCell>{user.firstname + user.lastname}</TableCell>
                                                <TableCell align="right">{user.mobile}</TableCell>
                                                <TableCell align="right">{user.company}</TableCell>
                                                <TableCell align="right">{user.email}</TableCell>
                                                <TableCell align="right">{user.gstno}</TableCell>
                                                <TableCell align="right">{user.address}</TableCell>
                                                <TableCell align="right"><Button onClick={(e) => handleApproval(user.email, e)}>Approve</Button></TableCell>
                                                <TableCell align="right"><Button onClick={(e) => handleDeleteUser(user.company, e)}>Delete</Button></TableCell>
                                            </TableRow>
                                        )}

                                    </TableBody>
                                </Table>
                            }
                        </TableContainer>
                    </div>

                </AdminAuth>
            </UserGuard>
        </div>
    );
}

export default Home;