import React from "react";
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogActions from '@mui/material/DialogActions';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import Topbar from "../components/Topbar";
import AdminAuth from "../hooks/AdminAuth";
import FormControl from '@mui/material/FormControl';
import axios from 'axios'
import { Alert, Autocomplete, DialogContent } from '@mui/material';
import UserGuard from "../hooks/UserGuard";
import useAuth from "../hooks/useAuth";
import Loading from "../components/Loading";
import Skeleton from '@mui/material/Skeleton';
import { styled } from '@mui/material/styles';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Snackbar from '@mui/material/Snackbar';
import PropTypes from 'prop-types';
import { defaultTable, seedTable, fertlizerTable, chemicalsTable, farmMachATable, farmMachBTable, farmMachCTable, pesticidesTable, tarpaulinsTable, microIrrigationTable, groupList } from './Constants'

import InfoIcon from '@mui/icons-material/Info';
import Tooltip from '@mui/material/Tooltip';
import Switch from '@mui/material/Switch';



const theme = createTheme({
  palette: {
    primary: {
      main: '#0b3b66'
    },
    secondary: {
      main: '#0b3b66'
    },
  },
  typography: {
    fontWeightBold: 700
  }
});

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary,
}));



function BootstrapDialogTitle(props) {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};



const Product = () => {
  //States
  const [reqData, setReqData] = React.useState({})
  const [previewData, setPreviewData] = React.useState([])
  const [companyName, setCompanyName] = React.useState("");
  const [alertText, setAlertText] = React.useState("")
  const { uomidList, setuomidList, companyList, setCompanyList } = useAuth();
  const [uomId, setuomid] = React.useState("");
  const [uomName, setUomName] = React.useState("");
  const [loading, setLoading] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const [openPreview, setOpenPreview] = React.useState(false);
  const [snackbarMessage, setSnackbarMessage] = React.useState("");

  const [applicationIDList, setApplicationIDList] = React.useState([]);
  const [applicationID, setApplicationID] = React.useState("");
  const [applicationName, setApplicationName] = React.useState("");

  const [seedsProducerList, setSeedsProducerList] = React.useState([]);
  const [manufacturerID, setManufacturerID] = React.useState("");
  const [producerName, setProducerName] = React.useState("");

  const [seedsClassList, setSeedsClassList] = React.useState([]);
  const [seedsClassID, setSeedsClassID] = React.useState("");
  const [seedsClassName, setSeedsClassName] = React.useState("");

  const [itemCategoryList, setItemCategoryList] = React.useState([]);
  const [itemSubCategoryList, setItemSubCategoryList] = React.useState([]);
  const [itemDetailsList, setItemDetailsList] = React.useState([{ ItemName: "Other" }]);
  const [itemDetail, setItemDetail] = React.useState({});
  const [itemName, setItemName] = React.useState("");
  const [groupName, setGroupName] = React.useState("");
  const [weight, setWeight] = React.useState("");
  const [defaultWay, setDefaultWay] = React.useState(false);
  const [localProduct, setLocalProduct] = React.useState(false);
  const [createOrUpdate, setCreateOrUpdate] = React.useState(false);


  const handleGroupChange = (e, groupname) => {
    if (groupname !== null) {
      setGroupName(groupname)
    }
  }

  const handleToggleChange = (event) => {
    setLocalProduct(event.target.checked);
    console.log(event.target.checked)
  };

  const handleCreateOrUpdateToggleChange = (event) => {
    setCreateOrUpdate(event.target.checked);
    console.log(event.target.checked)
  };

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenPreview(false);
    setOpen(false);
  };

  const action = (
    <React.Fragment>
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={handleClose}
      >
        <CloseIcon fontSize="small" />
      </IconButton>
    </React.Fragment>
  );

  React.useEffect(() => {
    async function getCompanyList() {
      const token = localStorage.getItem('token');
      const configs = {
        headers: { 'Authorization': `Bearer ${token}` }
      }
      try {
        const res = await axios.get('/api/admin/getCompanyList', configs);
        console.log(res)
        setCompanyList(res.data);
      }
      catch (error) {
        console.log(error)
        setOpen(true);
        setSnackbarMessage("Error loading company list");
      }
    }

    async function getUomID() {
      const token = localStorage.getItem('token');
      const configs = {
        headers: { 'Authorization': `Bearer ${token}` }
      }
      try {
        const res = await axios.get('/api/admin/getUnitOfMeasurements', configs);
        console.log(res)
        setuomidList(res.data);
      }
      catch (error) {
        console.log(error);
        setOpen(true);
        setSnackbarMessage("Error while getting UomID")
      }
    }

    async function getApplicationID() {
      const token = localStorage.getItem('token');
      const configs = {
        headers: { 'Authorization': `Bearer ${token}` }
      }
      try {
        const res = await axios.get('/api/admin/getApplicationID', configs);
        console.log(res)
        console.log(res.data)
        setApplicationIDList(res.data);
      }
      catch (error) {
        console.log(error);
        setOpen(true);
        setSnackbarMessage("Error while getting UomID")
      }
    }

    async function getSeedsClass() {
      const token = localStorage.getItem('token');
      const configs = {
        headers: { 'Authorization': `Bearer ${token}` }
      }
      try {
        const res = await axios.get('/api/admin/getSeedsClass', configs);
        console.log(res)
        console.log(res.data)
        setSeedsClassList(res.data);
      }
      catch (error) {
        console.log(error);
        setOpen(true);
        setSnackbarMessage("Error while getting seed class")
      }
    }

    async function getSeedsProducer() {
      const token = localStorage.getItem('token');
      const configs = {
        headers: { 'Authorization': `Bearer ${token}` }
      }
      try {
        const res = await axios.get('/api/admin/getSeedsProduer', configs);
        console.log(res)
        console.log(res.data)
        setSeedsProducerList(res.data);
      }
      catch (error) {
        console.log(error);
        setOpen(true);
        setSnackbarMessage("Error while getting UomID")
      }
    }


    if (companyList.length === 0)
      getCompanyList()
    if (uomidList.length === 0)
      getUomID()
    if (applicationIDList.length === 0)
      getApplicationID()
    if (seedsClassList.length === 0)
      getSeedsClass()
    if (seedsProducerList.length === 0)
      getSeedsProducer()
  }, [])

  React.useEffect(() => {
    async function createProduct() {
      const token = localStorage.getItem('token');
      const configs = {
        headers: { 'Authorization': `Bearer ${token}` }
      }
      try {
        const axiosRes = await axios.post('/api/admin/createProduct', reqData, configs);
        console.log(axiosRes)
        if (axiosRes.status === 200) {
          setAlertText('Product Created Successfully');
        }
        else {
          console.log(axiosRes)
          setOpen(true);
          setSnackbarMessage("Unknown error occured seed producer")
        }
      }
      catch (error) {
        if (error.response) {
          console.log(error);
          setOpen(true);
          setSnackbarMessage(error.response.data.message);
        }
        else {
          console.log(error);
          setOpen(true);
          setSnackbarMessage("Internal Server Error")
        }
      }
      setLoading(false);
    }

    async function updateProduct() {
      const token = localStorage.getItem('token');
      const configs = {
        headers: { 'Authorization': `Bearer ${token}` }
      }
      try {
        const axiosRes = await axios.post('/api/admin/updateProduct', reqData, configs);
        console.log(axiosRes)
        if (axiosRes.status === 200) {
          setAlertText('Product Updated Successfully');
        }
        else {
          console.log(axiosRes)
          setOpen(true);
          setSnackbarMessage("Unknown error occured")
        }
      }
      catch (error) {
        if (error.response) {
          console.log(error);
          setOpen(true);
          setSnackbarMessage(error.response.data.message);
        }
        else {
          console.log(error);
          setOpen(true);
          setSnackbarMessage("Internal Server Error")
        }
      }
      setLoading(false);
    }

    console.log(Object.keys(reqData).length)
    if (createOrUpdate) {
      if (Object.keys(reqData).length !== 0) {
        setLoading(true)
        updateProduct()
      }
    }
    else {
      if (Object.keys(reqData).length !== 0) {
        setLoading(true)
        createProduct()
      }
    }

  }, [reqData])

  React.useEffect(() => {
    async function getItemCategory() {
      const token = localStorage.getItem('token');
      const configs = {
        headers: { 'Authorization': `Bearer ${token}` }
      }
      const categoryReqData = {
        "applicationId": applicationID,
        "applicationName": applicationName
      }
      try {
        const axiosRes = await axios.post('/api/admin/getItemCategory', categoryReqData, configs);
        console.log(axiosRes)
        setItemCategoryList(axiosRes.data)
      }
      catch (error) {
        if (error.response) {
          console.log(error);
          setSnackbarMessage(error.response.data.message);
        }
        else {
          console.log(error);
          setSnackbarMessage("Internal Server Error")
        }
      }
    }

    async function getItemSubCategory() {
      const token = localStorage.getItem('token');
      const configs = {
        headers: { 'Authorization': `Bearer ${token}` }
      }
      const categoryReqData = {
        "applicationId": applicationID,
        "applicationName": applicationName
      }
      try {
        const axiosRes = await axios.post('/api/admin/getItemSubCategory', categoryReqData, configs);
        console.log(axiosRes)
        setItemSubCategoryList(axiosRes.data)
      }
      catch (error) {
        if (error.response) {
          console.log(error);
          setSnackbarMessage(error.response.data.message);
        }
        else {
          console.log(error);
          setSnackbarMessage("Internal Server Error")
        }
      }
    }

    async function getItemDetails() {
      const token = localStorage.getItem('token');
      const configs = {
        headers: { 'Authorization': `Bearer ${token}` }
      }
      const itemDetailsReqData = {
        "applicationId": applicationID,
        "applicationName": applicationName
      }
      try {
        const axiosRes = await axios.post('/api/admin/getItemDetails', itemDetailsReqData, configs);
        let productList = Array.from(new Set(axiosRes.data.map((item) => item.ItemName))).map(itemName => axiosRes.data.find(item => item.ItemName === itemName));
        setItemDetailsList([{ ItemName: "Other" }, ...productList]);

      }
      catch (error) {
        console.log(error);
        setItemDetailsList([{ ItemName: "Other" }]);
        setItemDetail({})
        setItemName("")
        if (error.response) {
          console.log(error);
          setOpen(true)
          setSnackbarMessage(error.response.data.message);
        }
        else {
          console.log(error);
          setOpen(true)
          setSnackbarMessage("Internal Server Error")
        }
      }
    }

    if (applicationID.length !== 0) {
      getItemCategory()
      getItemSubCategory()
      getItemDetails();
    }
  }, [applicationID])


  const AutoCompleteField = ({ id, options, value, onChange, label, required }) => {
    return (
      <Grid item xs={6} style={{ paddingTop: '10px', paddingRight: '10px' }}>
        <FormControl fullWidth required>
          <Autocomplete
            id={id}
            options={options}
            value={value}
            onChange={onChange}
            renderInput={(params) => (
              <TextField
                required={required}
                {...params}
                label={label}
              />
            )}
          />
        </FormControl>
      </Grid>
    );
  };
  
  const UOMName = () => (
    <AutoCompleteField
      id="uom-name"
      options={uomidList.map((p) => p.UomName)}
      value={uomName}
      onChange={handleUOMChange}
      label="Select UOM Name"
      required={true}
    />
  );
  
  const SeedsClass = () => (
    <AutoCompleteField
      id="seeds-class"
      options={seedsClassList.map((p) => p.ClassName)}
      value={seedsClassName}
      onChange={handleSeedsClassChange}
      label="Select Seeds Class Name"
      required={defaultWay ? false : true}
    />
  );

  const ManufacturerName = () => (
    <AutoCompleteField
      id="manufacturer name"
      options={seedsProducerList.map((p) => p.ProducerName)}
      value={producerName}
      onChange={handleSeedsProducerChange}
      label="Select Seeds Producer Name"
      required={true}
    />
  );


  const handleChange = (event, company) => {
    console.log(company);
    if (company !== null) {
      setCompanyName(company);
    }
  };

  const handleApplicationIDChange = (event, application) => {
    console.log(application);
    if (application !== null) {
      setApplicationName(application);
    }
    for (let i = 0; i < applicationIDList.length; i++) {
      if (application === applicationIDList[i].ApplicationName) {
        console.log(applicationIDList[i].ApplicationID)
        setApplicationID(applicationIDList[i].ApplicationID)
        break;
      }
    }
  };

  const handleItemDetailChange = (event, item) => {
    if (item !== null) {
      setItemName(item);
    }
    if (item !== "Other") {
      for (let i = 0; i < itemDetailsList.length; i++) {
        if (item === itemDetailsList[i].ItemName) {
          console.log(itemDetailsList[i])
          setItemDetail(itemDetailsList[i])
          break;
        }
      }
    }
    else {
      setDefaultWay(true);
    }
    // setProductCode(newValue.productCode);
  };

  const handleSubmit = (event) => {

    event.preventDefault();
    console.log(previewData[0])
    setReqData(previewData[0]);
    setOpenPreview(false)
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });

  };

  const handleUOMChange = (event, uomName) => {
    console.log(event.target.value, uomName)
    setUomName(uomName);
    for (let i = 0; i < uomidList.length; i++) {
      if (uomName === uomidList[i].UomName) {
        console.log(uomidList[i].UomID)
        setuomid(uomidList[i].UomID)
        break;
      }
    }
  };


  const handleSeedsClassChange = (event, seedsClassName) => {
    console.log(event.target.value, seedsClassName)
    setSeedsClassName(seedsClassName);
    for (let i = 0; i < seedsClassList.length; i++) {
      if (seedsClassName === seedsClassList[i].ClassName) {
        console.log(seedsClassList[i].ClassId)
        setSeedsClassID(seedsClassList[i].ClassId)
        break;
      }
    }
  };

  const handleSeedsProducerChange = (event, seedsProducer) => {
    console.log(event.target.value, seedsProducer)
    setProducerName(seedsProducer);
    for (let i = 0; i < seedsProducerList.length; i++) {
      if (seedsProducer === seedsProducerList[i].ProducerName) {
        console.log(seedsProducerList[i].ManufacturerId)
        setManufacturerID(seedsProducerList[i].ManufacturerId)
        break;
      }
    }
  };

  const handlePreview = (event) => {
    event.preventDefault();
    setAlertText("")
    const data = new FormData(event.currentTarget);

    if (companyName === "") {
      setOpen(true);
      setSnackbarMessage("Select Company");
    }
    else if (applicationID === "") {
      setOpen(true);
      setSnackbarMessage("Select Application Id");
    }
    else if (itemName === "") {
      setOpen(true);
      setSnackbarMessage("Select Product Name");
    }
    else {
      if ((itemDetail.length === 0 || uomidList.length === 0 || itemCategoryList.length === 0 || itemSubCategoryList.length === 0) && !defaultWay) {
        setOpen(true);
        setSnackbarMessage("Internal Server Error, Please Try again");
      }
      else {
        const uomObj = uomidList.find(obj => obj.UomID === itemDetail.UomID);
        console.log(uomObj, uomidList, itemDetail);
        const categoryObj = itemCategoryList.find(obj => obj.ItemCategoryID === itemDetail.ItemCategoryID);
        const subCategoryObj = itemSubCategoryList.find(obj => obj.SubCategoryID === itemDetail.SubCategoryID);

        let reqPayload;


        if (defaultWay === true) {
          reqPayload = {
            company: companyName,
            group: groupName,
            applicationid: applicationID,
            productcode: data.get("productCode"),
            manufacturername: data.get("manufacturerName"),
            manufacturerid: data.get("manufacturerId"),
            seedsclassid: seedsClassID,
            seedsclassname: seedsClassName,
            suppliername: data.get("supplierName"),
            categoryid: data.get("categoryId"),
            categoryname: data.get("categoryName"),
            subcategoryid: data.get("subCategoryId"),
            subcategoryname: data.get("subCategoryName"),
            itemid: data.get("itemId"),
            productname: data.get("productName"),
            brandname: data.get("brandName"),
            uomid: uomId.length !== 0 ? uomId : null,
            uomname: uomName.length !== 0 ? uomName : uomObj.UomName,
            weight: data.get("weight"),
            productalias: data.get("productAlias"),
            licensenumber: data.get("licenseNumber"),
            isstandard: data.get("isStandard"),
            cmlnumber: data.get("cmlNumber"),
            gsm: data.get("gsm"),
            bisstandard: data.get("bisStandard"),
            marketedby: data.get("marketedBy"),
            cibregistrationcertificate: data.get("cibRegistrationCertificate"),
            serialno: data.get("serialNo").length !== 0 ? data.get("serialNo") : "1",
            islocalproduct: localProduct
          }
        }
        else if (groupName === "SEEDS") {
          reqPayload = {
            company: companyName,
            group: groupName,
            applicationid: applicationID,
            productcode: data.get("productCode"),
            manufacturername: producerName,
            manufacturerid: manufacturerID,
            seedsclassid: seedsClassID,
            seedsclassname: seedsClassName,
            suppliername: data.get("supplierName"),
            categoryid: itemDetail.ItemCategoryID,
            categoryname: categoryObj.ItemCategoryName,
            subcategoryid: itemDetail.SubCategoryID,
            subcategoryname: subCategoryObj.SubCategoryName,
            itemid: itemDetail.ItemID,
            productname: itemDetail.ItemName,
            brandname: data.get("brandName"),
            uomid: itemDetail.UomID,
            uomname: uomObj.UomName,
            weight: itemDetail.PacketSize,
            productalias: data.get("productAlias"),
            licensenumber: null,
            websiteaddress: null,
            isstandardsofengine: null,
            cmlnumberforengine: null,
            isstandardsforpump: null,
            cmlnumberforpump: null,
            isstandard: null,
            cmlnumber: null,
            gsm: null,
            bisstandard: null,
            marketedby: null,
            cibregistrationcertificate: null,
            serialno: "1",
            islocalproduct: localProduct
          }
        }
        else if (groupName === "FERTILIZER") {
          reqPayload = {
            company: companyName,
            group: groupName,
            applicationid: applicationID,
            productcode: data.get("productCode"),
            manufacturername: data.get("manufacturerName"),
            suppliername: data.get("supplierName"),
            categoryid: itemDetail.ItemCategoryID,
            categoryname: categoryObj.ItemCategoryName,
            subcategoryid: itemDetail.SubCategoryID,
            subcategoryname: subCategoryObj.SubCategoryName,
            itemid: itemDetail.ItemID,
            productname: itemDetail.ItemName,
            brandname: data.get("brandName"),
            uomid: itemDetail.UomID,
            uomname: uomObj.UomName,
            weight: itemDetail.PacketSize,
            productalias: data.get("productAlias"),
            licensenumber: null,
            websiteaddress: null,
            isstandardsofengine: null,
            cmlnumberforengine: null,
            isstandardsforpump: null,
            cmlnumberforpump: null,
            isstandard: null,
            cmlnumber: null,
            gsm: null,
            bisstandard: null,
            marketedby: null,
            cibregistrationcertificate: null,
            serialno: "1",
            islocalproduct: localProduct
          }
        }
        else if (groupName === "PESTICIDES" || groupName === "CHEMICALS") {
          reqPayload = {
            company: companyName,
            group: groupName,
            applicationid: applicationID,
            productcode: data.get("productCode"),
            manufacturername: data.get("manufacturerName"),
            suppliername: data.get("supplierName"),
            categoryid: itemDetail.ItemCategoryID,
            categoryname: categoryObj.ItemCategoryName,
            subcategoryid: itemDetail.SubCategoryID,
            subcategoryname: subCategoryObj.SubCategoryName,
            itemid: itemDetail.ItemID,
            productname: itemDetail.ItemName,
            brandname: data.get("brandName"),
            licensenumber: data.get("licensenumber"),
            uomid: uomId.length !== 0 ? uomId : itemDetail.UomID,
            uomname: uomName.length !== 0 ? uomName : uomObj.UomName,
            weight: data.get('weight') ? data.get('weight') : itemDetail.PacketSize,
            productalias: data.get("productAlias"),
            marketedby: groupName === 'PESTICIDES' ? data.get('marketedBy') : "",
            cibregistrationcertificate: groupName === 'PESTICIDES' ? data.get('cibRegistrationCertificate') : "",
            websiteaddress: null,
            isstandardsofengine: null,
            cmlnumberforengine: null,
            isstandardsforpump: null,
            cmlnumberforpump: null,
            isstandard: null,
            cmlnumber: null,
            gsm: null,
            bisstandard: null,
            serialno: "1",
            islocalproduct: localProduct
          }
        }
        else if (groupName === "Farm Machinery Part-A" || groupName === "Farm Machinery Part-B") {
          reqPayload = {
            company: companyName,
            group: groupName,
            applicationid: applicationID,
            productcode: data.get("productCode"),
            manufacturername: data.get("manufacturerName"),
            suppliername: data.get("supplierName"),
            categoryid: itemDetail.ItemCategoryID,
            categoryname: categoryObj.ItemCategoryName,
            subcategoryid: itemDetail.SubCategoryID,
            subcategoryname: subCategoryObj.SubCategoryName,
            itemid: itemDetail.ItemID,
            productname: itemDetail.ItemName,
            brandname: data.get("brandName"),
            websiteaddress: data.get("websiteAddress"),
            uomid: itemDetail.UomID,
            uomname: uomObj.UomName,
            weight: itemDetail.PacketSize,
            productalias: data.get("productAlias"),
            licensenumber: null,
            isstandardsofengine: null,
            cmlnumberforengine: null,
            isstandardsforpump: null,
            cmlnumberforpump: null,
            isstandard: null,
            cmlnumber: null,
            gsm: null,
            bisstandard: null,
            marketedby: null,
            cibregistrationcertificate: null,
            serialno: "1",
            islocalproduct: localProduct
          }
        }
        else if (groupName === "Farm Machinery Part-C") {
          reqPayload = {
            company: companyName,
            group: groupName,
            applicationid: applicationID,
            productcode: data.get("productCode"),
            manufacturername: data.get("manufacturerName"),
            suppliername: data.get("supplierName"),
            categoryid: itemDetail.ItemCategoryID,
            categoryname: categoryObj.ItemCategoryName,
            subcategoryid: itemDetail.SubCategoryID,
            subcategoryname: subCategoryObj.SubCategoryName,
            itemid: itemDetail.ItemID,
            productname: itemDetail.ItemName,
            brandname: data.get("brandName"),
            isstandardsofengine: data.get("isStandardsOfEngine"),
            cmlnumberforengine: data.get("cmlNumberForEngine"),
            isstandardsforpump: data.get("isStandardsForPump"),
            cmlnumberforpump: data.get("cmlNumberForPump"),
            websiteaddress: data.get("websiteAddress"),
            uomid: itemDetail.UomID,
            uomname: uomObj.UomName,
            weight: itemDetail.PacketSize,
            productalias: data.get("productAlias"),
            licensenumber: null,
            isstandard: null,
            cmlnumber: null,
            gsm: null,
            bisstandard: null,
            marketedby: null,
            cibregistrationcertificate: null,
            serialno: "1",
            islocalproduct: localProduct
          }
        }
        else if (groupName === "TARPAULINS") {
          reqPayload = {
            company: companyName,
            group: groupName,
            applicationid: applicationID,
            productcode: data.get("productCode"),
            manufacturername: data.get("manufacturerName"),
            suppliername: data.get("supplierName"),
            categoryid: itemDetail.ItemCategoryID,
            categoryname: categoryObj.ItemCategoryName,
            subcategoryid: itemDetail.SubCategoryID,
            subcategoryname: subCategoryObj.SubCategoryName,
            itemid: itemDetail.ItemID,
            productname: itemDetail.ItemName,
            isstandard: data.get("isStandard"),
            cmlnumber: data.get("cmlNo"),
            gsm: data.get("gsm"),
            uomid: '5',
            uomname: 'Number',
            weight: 1,
            productalias: data.get("productAlias"),
            brandname: data.get("brandName"),
            licensenumber: null,
            websiteaddress: null,
            isstandardsofengine: null,
            cmlnumberforengine: null,
            isstandardsforpump: null,
            cmlnumberforpump: null,
            bisstandard: null,
            marketedby: null,
            cibregistrationcertificate: null,
            serialno: "1",
            islocalproduct: localProduct
          }
        }
        else if (groupName === "MICRO IRRIGATION") {
          reqPayload = {
            company: companyName,
            group: groupName,
            applicationid: applicationID,
            productcode: data.get("productCode"),
            manufacturername: data.get("manufacturerName"),
            suppliername: data.get("supplierName"),
            categoryid: itemDetail.ItemCategoryID,
            categoryname: categoryObj.ItemCategoryName,
            subcategoryid: itemDetail.SubCategoryID,
            subcategoryname: subCategoryObj.SubCategoryName,
            itemid: itemDetail.ItemID,
            productname: itemDetail.ItemName,
            bisstandard: data.get("bisStandard"),
            cmlnumber: data.get("cmlNo"),
            uomid: uomId.length !== 0 ? uomId : itemDetail.UomID,
            uomname: uomName.length !== 0 ? uomName : uomObj.UomName,
            weight: data.get('weight') ? data.get('weight') : itemDetail.PacketSize,
            productalias: data.get("productAlias"),
            brandname: data.get("brandName"),
            licensenumber: null,
            websiteaddress: null,
            isstandardsofengine: null,
            cmlnumberforengine: null,
            isstandardsforpump: null,
            cmlnumberforpump: null,
            isstandard: null,
            gsm: null,
            marketedby: null,
            cibregistrationcertificate: null,
            serialno: "1",
            islocalproduct: localProduct
          }
        }

        console.log(reqPayload)
        setPreviewData([reqPayload]);
        setOpenPreview(true)
        window.scrollTo({
          top: 0,
          behavior: 'smooth'
        });
      }
    }
  };

  return (
    <UserGuard>
      <AdminAuth>
        <ThemeProvider theme={theme}>
          <Topbar />
          {alertText !== "" ? <Alert severity='success'>{alertText}</Alert> : <div></div>}
          {open === true ? <Snackbar
            open={open}
            autoHideDuration={6000}
            onClose={handleClose}
            message={snackbarMessage}
            action={action}
          /> : <></>}
          <Container component="main">
            <CssBaseline />
            <Box
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
              }}
            >
              <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
                <LockOutlinedIcon />
              </Avatar>
              <Typography component="h1" variant="h5">
                Enter Product Details
              </Typography>
              {loading === true ? <Loading /> :
                <Box component="form" onSubmit={handlePreview}>
                  <Grid container style={{ width: '90vw', display: 'flex', justifyContent: 'center', alignContent: 'center', alignItems: 'center' }}>
                    <Grid item xs={6} style={{ paddingTop: '10px', paddingRight: '10px' }}>
                      <FormControl fullWidth required>
                        <Autocomplete
                          id="combo-box-demo"
                          options={companyList}
                          value={companyName}
                          onChange={handleChange}
                          renderInput={(params) => (
                            <TextField
                              required
                              {...params}
                              label="Select Your Company"
                            />
                          )}
                        />
                      </FormControl>
                    </Grid>
                    <Grid item xs={6} style={{ paddingTop: '10px', paddingRight: '10px' }}>
                      <FormControl fullWidth required>
                        <Autocomplete
                          id="combo-box-demo"
                          options={groupList.map((p) => p.name)}
                          value={groupName}
                          onChange={handleGroupChange}
                          renderInput={(params) => (
                            <TextField
                              required
                              {...params}
                              label="Select Group"
                            />
                          )}
                        />
                      </FormControl>
                    </Grid>
                    {groupName !== "" ? <>
                      <Grid container itemSpacing={2}>
                        <Grid item xs={6}
                          style={{ paddingTop: '10px', paddingRight: '10px' }}>
                          <FormControl fullWidth required>
                            <Autocomplete
                              id="combo-box-demo"
                              options={applicationIDList.map((p) => p.ApplicationName)}
                              value={applicationName}
                              onChange={handleApplicationIDChange}
                              renderInput={(params) => (
                                <TextField
                                  required
                                  {...params}
                                  label="Select ApplicationID"
                                />
                              )}
                            />
                          </FormControl>
                        </Grid>
                        <Grid item xs={6}
                          style={{ paddingTop: '10px', paddingRight: '10px' }}
                        >
                          {itemDetailsList.length === 0 ? <Skeleton variant="rounded" fullWidth height={60} /> :
                            <FormControl fullWidth required
                              style={{ margin: '5px' }}
                            >
                              <Autocomplete
                                id="combo-box-demo"
                                options={itemDetailsList.map((p) => p.ItemName)}
                                value={itemName}
                                onChange={handleItemDetailChange}
                                renderInput={(params) => (
                                  <TextField
                                    required
                                    {...params}
                                    label="Select Product Name"
                                  />
                                )}
                              />
                            </FormControl>
                          }
                        </Grid>
                      </Grid>

                      {defaultWay === false ? <Grid container style={{ marginTop: '10px' }}>
                        {groupName === 'SEEDS' ?
                          <Grid container spacing={2}>
                            {seedTable.map((field, index) => (
                              field.id === 'uomName' ? <UOMName />
                                : field.id === 'seedsClass' ? <SeedsClass /> :
                                field.id === 'manufacturerNameAuto' ? <ManufacturerName /> :
                                <Grid
                                  key={index}
                                  item
                                  style={{ paddingTop: '10px', paddingRight: '10px' }}
                                  xs={seedTable.length % 2 !== 0 && index === seedTable.length - 1 ? 12 : 6}
                                >
                                  <TextField
                                    required
                                    label={field.label}
                                    name={field.name}
                                    variant="outlined"
                                    fullWidth
                                    id={field.id}
                                  />
                                </Grid>
                            ))}
                          </Grid>
                          : (groupName === 'FERTILIZER' ?
                            <Grid container spacing={2}>
                              {fertlizerTable.map((field, index) => (
                                field.id === 'uomName' ? <UOMName />
                                  :
                                  <Grid
                                    key={index}
                                    item
                                    style={{ paddingTop: '10px', paddingRight: '10px' }}
                                    xs={fertlizerTable.length % 2 !== 0 && index === fertlizerTable.length - 1 ? 12 : 6}
                                  >
                                    <TextField
                                      required
                                      label={field.label}
                                      name={field.name}
                                      variant="outlined"
                                      fullWidth
                                      id={field.id}
                                    />
                                  </Grid>
                              ))}
                            </Grid>
                            : (groupName === 'PESTICIDES' ?
                              <Grid container spacing={2}>
                                {pesticidesTable.map((field, index) => (
                                  field.id === 'uomName' ? <UOMName />
                                    :
                                    <Grid
                                      key={index}
                                      item
                                      style={{ paddingTop: '10px', paddingRight: '10px' }}
                                      xs={pesticidesTable.length % 2 !== 0 && index === pesticidesTable.length - 1 ? 12 : 6}
                                    >
                                      <TextField
                                        required
                                        label={field.label}
                                        name={field.name}
                                        variant="outlined"
                                        fullWidth
                                        id={field.id}
                                      />
                                    </Grid>
                                ))}
                              </Grid>
                              : (groupName === 'CHEMICALS' ?
                                <Grid container spacing={2}>
                                  {chemicalsTable.map((field, index) => (
                                    field.id === 'uomName' ? <UOMName />
                                      :
                                      <Grid
                                        key={index}
                                        item
                                        style={{ paddingTop: '10px', paddingRight: '10px' }}
                                        xs={chemicalsTable.length % 2 !== 0 && index === chemicalsTable.length - 1 ? 12 : 6}
                                      >
                                        <TextField
                                          required
                                          label={field.label}
                                          name={field.name}
                                          variant="outlined"
                                          fullWidth
                                          id={field.id}
                                        />
                                      </Grid>
                                  ))}
                                </Grid>
                                : (groupName === 'Farm Machinery Part-A' ?
                                  <Grid container spacing={2}>
                                    {farmMachATable.map((field, index) => (
                                      field.id === 'uomName' ? <UOMName />
                                        :
                                        <Grid
                                          key={index}
                                          item
                                          style={{ paddingTop: '10px', paddingRight: '10px' }}
                                          xs={farmMachATable.length % 2 !== 0 && index === farmMachATable.length - 1 ? 12 : 6}
                                        >
                                          <TextField
                                            required
                                            label={field.label}
                                            name={field.name}
                                            variant="outlined"
                                            fullWidth
                                            id={field.id}
                                          />
                                        </Grid>
                                    ))}
                                  </Grid>
                                  : (groupName === 'Farm Machinery Part-B' ?
                                    <Grid container spacing={2}>
                                      {farmMachBTable.map((field, index) => (
                                        field.id === 'uomName' ? <UOMName />
                                          :
                                          <Grid
                                            key={index}
                                            item
                                            style={{ paddingTop: '10px', paddingRight: '10px' }}
                                            xs={farmMachBTable.length % 2 !== 0 && index === farmMachBTable.length - 1 ? 12 : 6}
                                          >
                                            <TextField
                                              required
                                              label={field.label}
                                              name={field.name}
                                              variant="outlined"
                                              fullWidth
                                              id={field.id}
                                            />
                                          </Grid>
                                      ))}
                                    </Grid>
                                    : (groupName === 'Farm Machinery Part-C' ?
                                      <Grid container spacing={2}>
                                        {farmMachCTable.map((field, index) => (
                                          field.id === 'uomName' ? <UOMName />
                                            :
                                            <Grid
                                              key={index}
                                              item
                                              style={{ paddingTop: '10px', paddingRight: '10px' }}
                                              xs={farmMachCTable.length % 2 !== 0 && index === farmMachCTable.length - 1 ? 12 : 6}
                                            >
                                              <TextField
                                                required
                                                label={field.label}
                                                name={field.name}
                                                variant="outlined"
                                                fullWidth
                                                id={field.id}
                                              />
                                            </Grid>
                                        ))}
                                      </Grid>
                                      : (groupName === 'TARPAULINS' ?
                                        <Grid container spacing={2}>
                                          {tarpaulinsTable.map((field, index) => (
                                            field.id === 'uomName' ? <UOMName />
                                              :
                                              <Grid
                                                key={index}
                                                item
                                                style={{ paddingTop: '10px', paddingRight: '10px' }}
                                                xs={tarpaulinsTable.length % 2 !== 0 && index === tarpaulinsTable.length - 1 ? 12 : 6}
                                              >
                                                <TextField
                                                  required
                                                  label={field.label}
                                                  name={field.name}
                                                  variant="outlined"
                                                  fullWidth
                                                  id={field.id}
                                                />
                                              </Grid>
                                          ))}
                                        </Grid>
                                        : (groupName === 'MICRO IRRIGATION' ? <Grid container spacing={2}>
                                          {microIrrigationTable.map((field, index) => (
                                            field.id === 'uomName' ? <UOMName />
                                              :
                                              <Grid
                                                key={index}
                                                item
                                                style={{ paddingTop: '10px', paddingRight: '10px' }}
                                                xs={microIrrigationTable.length % 2 !== 0 && index === microIrrigationTable.length - 1 ? 12 : 6}
                                              >
                                                <TextField
                                                  required
                                                  label={field.label}
                                                  name={field.name}
                                                  variant="outlined"
                                                  fullWidth
                                                  id={field.id}
                                                />
                                              </Grid>
                                          ))}
                                        </Grid> : <></>
                                        ))))))))}
                      </Grid> :
                        <Grid container spacing={2} style={{ marginTop: '5px' }}>
                          {defaultTable.map((field, index) => (
                            field.id === 'uomName' ? <UOMName />
                              : field.id === 'seedsClass' ? <SeedsClass /> :
                              <Grid
                                key={index}
                                item
                                style={{ paddingTop: '10px', paddingRight: '10px' }}
                                xs={seedTable.length % 2 !== 0 && index === seedTable.length - 1 ? 12 : 6}
                              >
                                <TextField
                                  label={field.label}
                                  name={field.name}
                                  variant="outlined"
                                  fullWidth
                                  id={field.id}
                                />
                              </Grid>
                          ))}
                        </Grid>
                      }
                    </> : <></>
                    }
                  </Grid>
                  <div style={{ margin: "10px" }}>
                    <Tooltip
                      title='Enable the switch to avoid calling K-KISAN API'
                    >
                      <IconButton
                        color="primary"
                        aria-label="info"
                      >
                        <InfoIcon />
                      </IconButton>
                    </Tooltip>
                    <Switch checked={localProduct} onChange={handleToggleChange} />
                    <Tooltip
                      title='Enable the switch to update the product details'
                    >
                      <IconButton
                        color="primary"
                        aria-label="info"
                      >
                        <InfoIcon />
                      </IconButton>
                    </Tooltip>
                    <Switch checked={createOrUpdate} onChange={handleCreateOrUpdateToggleChange} />
                  </div>

                  <div>
                    <BootstrapDialog
                      aria-labelledby="customized-dialog-title"
                      open={openPreview}
                    >
                      <BootstrapDialogTitle id="customized-dialog-title" >
                        Product Details
                      </BootstrapDialogTitle>
                      <DialogContent dividers>
                        <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                          {previewData.map((res) => {
                            return Object.entries(res).map(([key, value]) => (
                              value ? (
                                <>
                                  <Grid item xs={6}>
                                    <Item><Typography variant="subtitle1" component="div" fontWeight="bold">
                                      {key === "company" ? "Company" : ""}
                                      {key === "group" ? "Group" : ""}
                                      {key === "applicationid" ? "Application ID" : ""}
                                      {key === "productcode" ? "Product Code" : ""}
                                      {key === "manufacturername" ? "Manufacturer Name" : ""}
                                      {key === "manufacturerid" ? "Manufacturer ID" : ""}
                                      {key === "seedsclassid" ? " Seeds Class ID" : ""}
                                      {key === "seedsclassname" ? "Seeds Class Name" : ""}
                                      {key === "suppliername" ? "Supplier Name" : ""}
                                      {key === "categoryid" ? "Category ID" : ""}
                                      {key === "categoryname" ? "Category Name" : ""}
                                      {key === "subcategoryid" ? "Subcategory ID" : ""}
                                      {key === "subcategoryname" ? "Subcategory Name" : ""}
                                      {key === "itemid" ? "Item ID" : ""}
                                      {key === "productname" ? "Product Name" : ""}
                                      {key === "bisstandard" ? "BIS Standard" : ""}
                                      {key === "cmlnumber" ? "CML Number" : ""}
                                      {key === "uomid" ? "UOM ID" : ""}
                                      {key === "uomname" ? "UOM Name" : ""}
                                      {key === "weight" ? "Weight" : ""}
                                      {key === "productalias" ? "Product Alias" : ""}
                                      {key === "brandname" ? "Brand Name" : ""}
                                      {key === "licensenumber" ? "License Number" : ""}
                                      {key === "websiteaddress" ? "Website Address" : ""}
                                      {key === "isstandardsofengine" ? "IS Standards of Engine" : ""}
                                      {key === "cmlnumberforengine" ? "CML Number for Engine" : ""}
                                      {key === "isstandardsforpump" ? "IS Standards for Pump" : ""}
                                      {key === "cmlnumberforpump" ? "CML Number for Pump" : ""}
                                      {key === "isstandard" ? "IS Standard" : ""}
                                      {key === "gsm" ? "GSM" : ""}
                                      {key === "marketedby" ? "Marketed By" : ""}
                                      {key === "cibregistrationcertificate" ? "CIB Registration Certificate" : ""}
                                      {key === "serialno" ? "Serial No" : ""}
                                    </Typography></Item>
                                  </Grid>
                                  <Grid item xs={6}>
                                    <Item> <Typography variant="body1" component="div">
                                      {value}
                                    </Typography></Item>
                                  </Grid>
                                </>
                              ) : null
                            ));
                          })}
                        </Grid>
                      </DialogContent>
                      <DialogActions>
                        <div>
                          <Button
                            variant="contained"
                            sx={{ mt: 1, mb: 1, mr: 5 }}
                            autoFocus
                            onClick={handleClose}>
                            Edit
                          </Button>
                          <Button
                            variant="contained"
                            sx={{ mt: 1, mb: 1, mr: 2 }}
                            autoFocus onClick={handleSubmit}>
                            {createOrUpdate ? 'Update Product' : 'Create Product'}
                          </Button>
                        </div>
                      </DialogActions>
                    </BootstrapDialog>
                  </div>

                  <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    sx={{ mt: 3, mb: 2 }}
                  >
                    Preview Product
                  </Button>

                </Box>
              }
            </Box>
          </Container>
        </ThemeProvider>
      </AdminAuth>
    </UserGuard>
  );
}

export default Product;