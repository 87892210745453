import React from "react";
import { useNavigate } from "react-router";
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import { Autocomplete, Switch, IconButton } from '@mui/material';
import Box from '@mui/material/Box';
import QrCode2OutlinedIcon from '@mui/icons-material/QrCode2Outlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import FormControl from '@mui/material/FormControl';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import Topbar from "../components/Topbar";
import axios from "axios";
import useAuth from "../hooks/useAuth";
import UserGuard from "../hooks/UserGuard";
import AdminAuth from "../hooks/AdminAuth";
import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import { DialogContentText } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import Skeleton from '@mui/material/Skeleton';
import Loading from "../components/Loading";
import Snackbar from '@mui/material/Snackbar';
import moment from 'moment-timezone'
import InfoIcon from '@mui/icons-material/Info';
import Tooltip from '@mui/material/Tooltip';
import './Qr.css'

const theme = createTheme({
  palette: {
    primary: {
      main: '#0b3b66'
    },
    secondary: {
      main: '#0b3b66'
    },
  },
  typography: {
    fontWeightBold: 700
  }
});

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary,
}));

function BootstrapDialogTitle(props) {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};




const PrimaryQR = () => {
  const [mfgDate, setMFGDate] = React.useState(moment(moment.tz('Asia/Kolkata').format('YYYY-MM-DD')));
  const [expiryDate, setExpiryDate] = React.useState(moment(moment.tz('Asia/Kolkata').format('YYYY-MM-DD')));
  const { isAuthenticated } = useAuth();
  const [companyName, setCompanyName] = React.useState("");
  const [productList, setProductList] = React.useState([]);
  const [productName, setProductName] = React.useState("");
  const [productAlias, setProductAlias] = React.useState("")
  const [reqData, setReqData] = React.useState({})
  const { currentUser, companyList, setCompanyList } = useAuth();
  const [open, setOpen] = React.useState(false);
  const [confirmScreenOpen, setConfirmScreenOpen] = React.useState(false);
  const [product, setProduct] = React.useState({});
  const [loading, setLoading] = React.useState(true);
  const [openSnackbar, setOpenSnackbar] = React.useState(false);
  const [snackbarMessage, setSnackbarMessage] = React.useState("");
  const [openLimitDialog, setOpenLimitDialog] = React.useState(false);
  const [groupName, setGroupName] = React.useState("")
  const [callExtApi, setCallExtApi] = React.useState(true);
  const [printQRCodesDialog, setPrintQRCodesDialog] = React.useState(false);
  const [generatedQRList, setGeneratedQRList] = React.useState([]);
  const [prevData, setPrevData] = React.useState({})

  const handleToggleChange = (event) => {
    setCallExtApi(event.target.callExtApi);
    console.log(callExtApi)
  };

  const handleLimitDialogClose = () => {
    setOpenLimitDialog(false);
  };

  const handlePrintQRCodesDialogOpen = () => {
    setPrintQRCodesDialog(true);
  };

  const handlePrintQRCodesDialogClose = () => {
    setPrintQRCodesDialog(false);
  };

  const handleSnackbarClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpenSnackbar(false);
  };

  const action = (
    <React.Fragment>
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={handleSnackbarClose}
      >
        <CloseIcon fontSize="small" />
      </IconButton>
    </React.Fragment>
  );


  React.useEffect(() => {

    async function getProductList() {
      const token = localStorage.getItem('token');
      const configs = {
        headers: { 'Authorization': `Bearer ${token}` }
      }
      const data = {
        company: currentUser.company
      }
      console.log(data)
      try {
        const res = await axios.post('/api/user/getProductList', data, configs);
        console.log(res)
        if (res.status === 200) {
          console.log(res.data)
          setProductList(res.data);
        }
        else {
          setOpenSnackbar(true);
          setSnackbarMessage("Unknown error occurred")
        }
      }
      catch (error) {
        console.log(error)
        if (error.response) {
          setOpenSnackbar(true)
          setSnackbarMessage(error.response.data.message)
        }
        else {
          setOpenSnackbar(true);
          setSnackbarMessage(error.message)
        }
      }
    }

    async function getCompanyList() {
      const token = localStorage.getItem('token');
      const configs = {
        headers: { 'Authorization': `Bearer ${token}` }
      }
      try {
        const res = await axios.get('/api/admin/getCompanyList', configs);
        if (res.status === 200) {
          console.log(res)
          setCompanyList(res.data);
        }
        else {
          setOpenSnackbar(true);
          setSnackbarMessage("Unknown error occurred")
        }
      }
      catch (error) {
        console.log(error)
        if (error.response) {
          setOpenSnackbar(true)
          setSnackbarMessage(error.response.data.message)
        }
        else {
          setOpenSnackbar(true);
          setSnackbarMessage(error.message)
        }
      }
    }

    console.log(companyList)
    if (companyList.length === 0 && currentUser.userrole === 'ADMIN')
      getCompanyList()

    if (currentUser.userrole === 'CLIENT')
      getProductList()

    setLoading(false)
  }, [])

  React.useEffect(() => {
    async function getProductList() {
      const token = localStorage.getItem('token');
      const configs = {
        headers: { 'Authorization': `Bearer ${token}` }
      }
      const data = {
        company: companyName
      }
      try {
        const res = await axios.post('/api/admin/getProductList', data, configs);
        console.log(res)
        if (res.status === 200) {
          setProductList(res.data);
        }
        else {
          setOpenSnackbar(true);
          setSnackbarMessage("Unknown error occurred")
        }
      }
      catch (error) {
        console.log(error)
        if (error.response) {
          setOpenSnackbar(true)
          setSnackbarMessage(error.response.data.message)
        }
        else {
          setOpenSnackbar(true);
          setSnackbarMessage(error.message)
        }
      }
    }
    if (companyName.length !== 0)
      getProductList()
  }, [companyName])

  React.useEffect(() => {
    async function getPrimaryQRCodeDetails() {
      try {
        console.log(reqData)
        const token = localStorage.getItem('token');
        const configs = {
        }
        const axiosResp = await axios.post('/api/qrcode/SavePrimaryQRDetailCtrl', reqData, configs);
        console.log(axiosResp);
        if (axiosResp.status === 200) {
          console.log(axiosResp);
          const qrCodeData = {
            "productCode": product.ProductCode,
            "productName": productName,
            "isContainer": false,
            "qrCodeArray": axiosResp.data
          }
          console.log(reqData);
          setGeneratedQRList(axiosResp.data);
          setPrintQRCodesDialog(true);
          // if (reqData.codestobegenerated > 500) {
          //   setOpenLimitDialog(true);
          // }
          // else
          //   navigate('/QRCodes', { state: qrCodeData });
        }
        else {
          setOpenSnackbar(true);
          setSnackbarMessage("Unknown error occurred")
        }
      }
      catch (error) {
        console.log(error)
        if (error.response) {
          setOpenSnackbar(true)
          setSnackbarMessage(error.response.data)
        }
        else {
          setOpenSnackbar(true);
          setSnackbarMessage(error.message)
        }
      }
      setLoading(false);
    }
    if (Object.keys(reqData).length !== 0) {
      setLoading(true);
      getPrimaryQRCodeDetails();
    }
  }, [reqData])


  const handleChange = (event, productalias) => {
    console.log(productalias)
    if (productalias !== null) {

      setProductAlias(productalias);
    }
    for (let i = 0; i < productList.length; i++) {
      console.log(productList[i])
      if (productalias === productList[i].productalias) {
        console.log(productList[i].productcode)
        setProductName(productList[i].productname)
        setProduct(productList[i])
        setGroupName(productList[i].group)
        console.log(3, groupName)
        console.log(productList[i].group)
        break;
      }
    }
    setOpen(true)
    console.log(product.productcode)
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleConfirmAndSubmitScreenClose = () => {
    setReqData(prevData);
    setConfirmScreenOpen(false);
  };

  const handleConfirmScreenClose = () => {
    setConfirmScreenOpen(false);
  };

  const handleCompanyChange = (event, newValue) => {
    console.log(newValue);
    if (newValue !== null) {
      setCompanyName(newValue);
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    console.log(isAuthenticated);
    const data = new FormData(event.currentTarget);
    console.log(expiryDate.format('DD/MM/YYYY'));
    console.log(mfgDate.format('DD/MM/YYYY'));
    console.log(product.productcode);
    console.log(data.get('bundleLateralSize'));
    const codestobegenerated = data.get('qrToBeGenerated');
    const batchNo = data.get('batchNumber');

    if (!(/^\d+$/.test(codestobegenerated))) {
      setSnackbarMessage('Invalid QRcode numbers to be generated')
      setOpenSnackbar(true)
      return;
    }
    if (codestobegenerated > 5000 || codestobegenerated < 1) {
      setSnackbarMessage("Maximum 5000 QR codes can be generated at a time")
      setOpenSnackbar(true)
      return;
    }
    if (batchNo && batchNo.length > 25) {
      setSnackbarMessage("Maximum length of batch number is 25")
      setOpenSnackbar(true)
      return;
    }

    let primaryQRData;
    if (groupName === 'SEEDS' || groupName === 'FERTILIZER' || groupName === 'PESTICIDES' || groupName === 'CHEMICALS') {
      primaryQRData = {
        productcode: product.productcode,
        batchnumber: data.get('batchNumber').toUpperCase() || null,
        manufacturedate: moment.tz(mfgDate, 'YYYY-MM-DD', 'Asia/Kolkata').format('DD/MM/YYYY'),
        expirydate: moment.tz(expiryDate, 'YYYY-MM-DD', 'Asia/Kolkata').format('DD/MM/YYYY'),
        codestobegenerated: data.get('qrToBeGenerated'),
        serialnumberofthemachinery: null,
        yearofmanufacturing: null,
        enginenumber: null,
        chasisnumber: null,
        pumpnumber: null,
        engineislicencevalidity: null,
        pumpislicencevalidity: null,
        bislicensevalidity: null,
        mfgmonthandyear: null,
        mrp: null,
        bisnumberandvalidity: null,
        cmlno: null,
        company: companyName,
        bundlelateralsize: null,
        isCallExtApi: callExtApi
      };
    } else if (groupName === 'Farm Machinery Part-A') {
      primaryQRData = {
        productcode: product.productcode,
        batchnumber: null,
        manufacturedate: null,
        expirydate: null,
        codestobegenerated: data.get('qrToBeGenerated'),
        serialnumberofthemachinery: data.get('serialNumberOfTheMachinery'),
        yearofmanufacturing: data.get('yearOfManufacturing'),
        mrp: data.get('mrp'),
        enginenumber: null,
        chasisnumber: null,
        pumpnumber: null,
        engineislicencevalidity: null,
        pumpislicencevalidity: null,
        bislicensevalidity: null,
        mfgmonthandyear: null,
        bisnumberandvalidity: null,
        cmlno: null,
        company: companyName,
        bundlelateralsize: null,
        isCallExtApi: callExtApi
      };
    } else if (groupName === 'Farm Machinery Part-B') {
      primaryQRData = {
        productcode: product.productcode,
        batchnumber: null,
        manufacturedate: null,
        expirydate: null,
        codestobegenerated: data.get('qrToBeGenerated'),
        serialnumberofthemachinery: data.get('serialNumberOfTheMachinery'),
        yearofmanufacturing: data.get('yearOfManufacturing'),
        mrp: data.get('mrp'),
        enginenumber: data.get('engineNumber'),
        chasisnumber: data.get('chasisNumber'),
        pumpnumber: null,
        engineislicencevalidity: null,
        pumpislicencevalidity: null,
        bislicensevalidity: null,
        mfgmonthandyear: null,
        bisnumberandvalidity: null,
        cmlno: null,
        company: companyName,
        bundlelateralsize: null,
        isCallExtApi: callExtApi
      };
    } else if (groupName === 'Farm Machinery Part-C') {
      primaryQRData = {
        productcode: product.productcode,
        batchnumber: null,
        manufacturedate: null,
        expirydate: null,
        codestobegenerated: data.get('qrToBeGenerated'),
        serialnumberofthemachinery: data.get('serialNumberOfTheMachinery'),
        yearofmanufacturing: data.get('yearOfManufacturing'),
        mrp: data.get('mrp'),
        enginenumber: data.get('engineNumber'),
        chasisnumber: null,
        pumpnumber: data.get('pumpNumber'),
        engineislicencevalidity: data.get('engineIsLicenceValidity'),
        pumpislicencevalidity: data.get('pumpIsLicenceValidity'),
        bislicensevalidity: null,
        mfgmonthandyear: null,
        bisnumberandvalidity: null,
        cmlno: null,
        company: companyName,
        bundlelateralsize: null,
        isCallExtApi: callExtApi
      };
    } else if (groupName === 'TARPAULINS') {
      primaryQRData = {
        productcode: product.productcode,
        batchnumber: data.get('batchNumber').toUpperCase() || null,
        manufacturedate: moment.tz(mfgDate, 'YYYY-MM-DD', 'Asia/Kolkata').format('DD/MM/YYYY'),
        expirydate: moment.tz(mfgDate, 'YYYY-MM-DD', 'Asia/Kolkata').add(2, 'years').format('DD/MM/YYYY'),
        codestobegenerated: data.get('qrToBeGenerated'),
        serialnumberofthemachinery: null,
        yearofmanufacturing: null,
        enginenumber: null,
        chasisnumber: null,
        pumpnumber: null,
        engineislicencevalidity: null,
        pumpislicencevalidity: null,
        bislicensevalidity: null,
        mfgmonthandyear: null,
        mrp: null,
        bisnumberandvalidity: null,
        cmlno: null,
        company: companyName,
        bundlelateralsize: null,
        isCallExtApi: callExtApi
      };
    } else if (groupName === 'MICRO IRRIGATION') {
      primaryQRData = {
        productcode: product.productcode,
        batchnumber: data.get('batchNumber').toUpperCase() || null,
        manufacturedate: null,
        expirydate: null,
        codestobegenerated: data.get('qrToBeGenerated'),
        serialnumberofthemachinery: null,
        yearofmanufacturing: null,
        mrp: null,
        enginenumber: null,
        chasisnumber: null,
        pumpnumber: null,
        engineislicencevalidity: null,
        pumpislicencevalidity: null,
        bislicensevalidity: null,
        mfgmonthandyear: null,
        bisnumberandvalidity: null,
        cmlno: null,
        company: companyName,
        bundlelateralsize: null,
        isCallExtApi: callExtApi
      };
    }
    console.log(primaryQRData);
    setPrevData(primaryQRData);
    setConfirmScreenOpen(true);
  };


  const generateDataForExcelRows = (qrList) => {
    let resArray = [];
    for (let i = 0; i < qrList.length; i++) {
      let dateformat = moment(qrList[i].createdat).tz('Asia/Kolkata')
        .format('DD/MM/YYYY HH:mm');
      let containerRange = "";
      if (qrList[i].containerrange.length !== 0) {
        containerRange = String("[ " + String(qrList[i].containerrange[0]) + "-" + String(qrList[i].containerrange[1]) + " ]");
      }


      let qrObj = {};
      if (groupName === 'SEEDS' || groupName === 'FERTILIZER' || groupName === 'PESTICIDES' || groupName === 'CHEMICALS') {
        qrObj = {
          ProductName: productName,
          ProductAlias: productAlias,
          SerialNumber: qrList[i].serialnumber,
          BatchNumber: qrList[i].batchnumber,
          ManufactureDate: qrList[i].manufacturedate,
          ExpiryDate: qrList[i].expirydate,
          ContainerQRCode: qrList[i].containerqrcode === "" ? "" : "\"=\"" + "00" + qrList[i].containerqrcode,
          ContainerRange: containerRange,
          QRNumber: "\"=\"" + "01" + String(qrList[i].qrcode),
          createdat: "\"=\"" + dateformat,
        }
      }
      else if (groupName === 'Farm Machinery Part-A') {
        qrObj = {
          ProductName: productName,
          ProductAlias: productAlias,
          SerialNumber: qrList[i].serialnumber,
          MachineSerialNumber: qrList[i].serialnumberofthemachinery,
          YearOfManufacture: qrList[i].yearofmanufacturing,
          QRNumber: "\"=\"" + "01" + String(qrList[i].qrcode),
          MRP: qrList[i].mrp,
          createdat: "\"=\"" + dateformat,
        }
      }
      else if (groupName === 'Farm Machinery Part-B') {
        qrObj = {
          ProductName: productName,
          ProductAlias: productAlias,
          SerialNumber: qrList[i].serialnumber,
          MachineSerialNumber: qrList[i].serialnumberofthemachinery,
          YearOfManufacture: qrList[i].yearofmanufacturing,
          QRNumber: "\"=\"" + "01" + String(qrList[i].qrcode),
          MRP: qrList[i].mrp,
          EngineNumber: qrList[i].enginenumber,
          ChasisNumber: qrList[i].chasisnumber,
          createdat: "\"=\"" + dateformat,
        }
      }
      else if (groupName === 'Farm Machinery Part-C') {
        qrObj = {
          ProductName: productName,
          ProductAlias: productAlias,
          SerialNumber: qrList[i].serialnumber,
          MachineSerialNumber: qrList[i].serialnumberofthemachinery,
          YearOfManufacture: qrList[i].yearofmanufacturing,
          QRNumber: "\"=\"" + "01" + String(qrList[i].qrcode),
          MRP: qrList[i].mrp,
          EngineNumber: qrList[i].enginenumber,
          PumpNumber: qrList[i].pumpnumber,
          EngineIsLicenceValidity: qrList[i].engineislicencevalidity,
          PumpIsLicenceValidity: qrList[i].pumpislicencevalidity,
          createdat: "\"=\"" + dateformat,
        }
      }
      else if (groupName === 'TARPAULINS') {
        qrObj = {
          ProductName: productName,
          ProductAlias: productAlias,
          SerialNumber: qrList[i].serialnumber,
          ManufactureDate: qrList[i].manufacturedate,
          ExpiryDate: qrList[i].expirydate,
          ContainerQRCode: qrList[i].containerqrcode === "" ? "" : "\"=\"" + "00" + qrList[i].containerqrcode,
          ContainerRange: containerRange,
          BatchNumber: qrList[i].batchnumber,
          QRNumber: "\"=\"" + "01" + String(qrList[i].qrcode),
          createdat: "\"=\"" + dateformat,
        }
      }
      else if (groupName === 'MICRO IRRIGATION') {
        qrObj = {
          ProductName: productName,
          ProductAlias: productAlias,
          SerialNumber: qrList[i].serialnumber,
          ContainerQRCode: qrList[i].containerqrcode === "" ? "" : "\"=\"" + "00" + qrList[i].containerqrcode,
          ContainerRange: containerRange,
          BatchNumber: qrList[i].batchnumber,
          QRNumber: "\"=\"" + "01" + String(qrList[i].qrcode),
          createdat: "\"=\"" + dateformat,
          BundleLateralSize: qrList[i].bundlelateralsize,
        }
      }
      resArray.push(qrObj);
    }
    return resArray;
  }


  const handleGeneratePDF = (flag) => {
    handlePrintQRCodesDialogClose();

    if (flag) {
      const qrCodeData = {
        "groupName": groupName,
        "productDetail": product,
        "isContainer": false,
        "qrCodeArray": generatedQRList,
        "excelRows": generateDataForExcelRows(generatedQRList)
      }
      console.log(qrCodeData);
      navigate('/QRCodes', { state: qrCodeData });
    }
  }


  const navigate = useNavigate();

  return (
    <div>
      <UserGuard>
        <Topbar />
        {loading === false ?
          <ThemeProvider theme={theme}>
            {openSnackbar === true ? <Snackbar
              open={openSnackbar}
              autoHideDuration={3000}
              onClose={handleSnackbarClose}
              message={snackbarMessage}
              action={action}
            /> : <></>}
            <div style={{ 'display': 'flex', 'flexDirection': 'row' }}>
              <Container component="main">
                <Dialog
                  open={printQRCodesDialog}
                  onClose={handlePrintQRCodesDialogClose}
                  aria-labelledby="alert-dialog-title"
                  aria-describedby="alert-dialog-description"
                >
                  <DialogTitle id="alert-dialog-title">
                    {"QR Codes are generated successfully"}
                  </DialogTitle>
                  <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                      Would you like print the QR codes?
                    </DialogContentText>
                  </DialogContent>
                  <DialogActions>
                    <Button onClick={() => handleGeneratePDF(false)}>No</Button>
                    <Button onClick={() => handleGeneratePDF(true)} autoFocus>
                      Yes
                    </Button>
                  </DialogActions>
                </Dialog>

                <Dialog
                  open={openLimitDialog}
                  onClose={handleLimitDialogClose}
                  aria-labelledby="alert-dialog-title"
                  aria-describedby="alert-dialog-description"
                >
                  <DialogTitle id="alert-dialog-title">
                    {"QR Codes are generated successfully"}
                  </DialogTitle>
                  <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                      QR codes are successfully registered in the KKISAN server.
                    </DialogContentText>
                  </DialogContent>
                  <DialogActions>
                    <Button onClick={handleLimitDialogClose} autoFocus>
                      Ok
                    </Button>
                  </DialogActions>
                </Dialog>
                <CssBaseline />
                <Box
                  sx={{
                    marginTop: 1,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                  }}
                >
                  <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
                    <QrCode2OutlinedIcon />
                  </Avatar>
                  <Typography component="h1" variant="h5">
                    Primary QR Codes
                  </Typography>
                  {loading === true ? <Loading /> :
                    <Box component="form" style={{ width: '900px' }} onSubmit={handleSubmit} sx={{ mt: 1 }}>
                      <AdminAuth>
                        <div style={{ "marginBottom": "10px", }}>
                          {companyList.length === 0 ?
                            <Skeleton variant="rounded" fullWidth height={60} /> :

                            <FormControl fullWidth required>
                              <Autocomplete
                                id="combo-box-demo"
                                options={companyList}
                                value={companyName}
                                onChange={handleCompanyChange}
                                renderInput={(params) => (
                                  <TextField
                                    required
                                    {...params}
                                    label="Select Your Company"
                                  />
                                )}
                              />
                            </FormControl>
                          }
                        </div>
                      </AdminAuth>
                      {productList.length === 0 ? <Skeleton variant="rounded" fullWidth height={60} /> :

                        <FormControl fullWidth required>
                          <Autocomplete
                            id="combo-box-demo"
                            options={productList.map((p) => p.productalias)}
                            value={productAlias}
                            onChange={handleChange}
                            renderInput={(params) => (
                              <TextField
                                required
                                {...params}
                                label="Select Your Product"
                              />
                            )}
                          />
                        </FormControl>
                      }

                      {groupName !== "" ? <>


                        <TextField
                          margin="normal"
                          required
                          fullWidth
                          name="qrToBeGenerated"
                          label="Number of QR Codes"
                          type="QRcode Quantity"
                          id="qrToBeGenerated"
                        />

                        {groupName === "SEEDS" || groupName === "FERTILIZER" || groupName === "PESTICIDES" || groupName === "CHEMICALS" ?
                          <>
                            <TextField
                              margin="normal"
                              required
                              fullWidth
                              id="batchNumber"
                              label="Batch Number"
                              name="batchNumber"
                              inputProps={{ maxLength: 25 }}
                            />

                            <div style={{ "marginBottom": "20px", "marginTop": "20px" }}>

                              <LocalizationProvider dateAdapter={AdapterMoment}>
                                <DatePicker
                                  label="Manufacture Date"
                                  value={mfgDate}
                                  onChange={(newValue) => setMFGDate(newValue)}
                                  format="DD/MM/YYYY"
                                />
                              </LocalizationProvider>
                            </div>
                            <LocalizationProvider dateAdapter={AdapterMoment}>
                              <DatePicker
                                label="Expiry Date"
                                value={expiryDate}
                                format="DD/MM/YYYY"
                                onChange={(newValue) => setExpiryDate(newValue)}
                              />
                            </LocalizationProvider>

                          </> :
                          <> </>
                        }
                        {
                          groupName === "Farm Machinery Part-A" || groupName === "Farm Machinery Part-B" || groupName === "Farm Machinery Part-C" ?
                            <>
                              <TextField
                                margin="normal"
                                required
                                fullWidth
                                id="serialNumberOfTheMachinery"
                                label="Serial Number of the Machinery"
                                name="serialNumberOfTheMachinery"
                              />

                            </> :
                            <> </>
                        }
                        {
                          groupName === "Farm Machinery Part-B" ?
                            <>
                              <TextField
                                margin="normal"
                                required
                                fullWidth
                                id="engineNumber"
                                label="Engine Number"
                                name="engineNumber"
                              />
                              <TextField
                                margin="normal"
                                required
                                fullWidth
                                id="chasisNumber"
                                label="Chasis Number"
                                name="chasisNumber"
                              />
                            </> :
                            <></>
                        }

                        {
                          groupName === "Farm Machinery Part-C" ?
                            <>
                              <TextField
                                margin="normal"
                                required
                                fullWidth
                                id="engineNumber"
                                label="Engine Number"
                                name="engineNumber"
                              />
                              <TextField
                                margin="normal"
                                required
                                fullWidth
                                id="pumpNumber"
                                label="Pump Number"
                                name="pumpNumber"
                              />
                              <TextField
                                margin="normal"
                                required
                                fullWidth
                                id="engineIsLicenceValidity"
                                label="Engine IS Licence Validity"
                                name="engineIsLicenceValidity"
                              />
                              <TextField
                                margin="normal"
                                required
                                fullWidth
                                id="pumpIsLicenceValidity"
                                label="Pump IS Licence Validity"
                                name="pumpIsLicenceValidity"
                              />
                            </> :
                            <></>
                        }

                        {
                          groupName === "Farm Machinery Part-A" || groupName === "Farm Machinery Part-B" || groupName === "Farm Machinery Part-C" ?
                            <>
                              <TextField
                                margin="normal"
                                required
                                fullWidth
                                id="yearOfManufacturing"
                                label="Year of Manufacturing"
                                name="yearOfManufacturing"
                              />
                              <TextField
                                margin="normal"
                                required
                                fullWidth
                                id="mrp"
                                label="MRP"
                                name="mrp"
                              />
                            </> :
                            <></>}

                        {
                          groupName === "TARPAULINS" ?
                            <>
                              <TextField
                                margin="normal"
                                required
                                fullWidth
                                id="batchNumber"
                                label="Batch Number"
                                name="batchNumber"
                                inputProps={{ maxLength: 25 }}
                              />
                              <div style={{ "marginBottom": "20px", "marginTop": "20px" }}>
                                <LocalizationProvider dateAdapter={AdapterMoment}>
                                  <DatePicker
                                    label="Manufacture Date"
                                    value={mfgDate}
                                    onChange={(newValue) => setMFGDate(newValue)}
                                    format="DD/MM/YYYY"
                                  />
                                </LocalizationProvider>
                              </div>
                            </> :
                            <></>
                        }

                        {
                          groupName === "MICRO IRRIGATION" ?
                            <>
                              <TextField
                                margin="normal"
                                required
                                fullWidth
                                id="batchNumber"
                                label="Batch Number"
                                name="batchNumber"
                                inputProps={{ maxLength: 25 }}
                              />
                            </>
                            :
                            <></>
                        }
                      </> :
                        <>
                        </>

                      }

                      {groupName === "Farm Machinery Part-A" || groupName === "Farm Machinery Part-B" || groupName === "Farm Machinery Part-C" ?
                        <div style={{ margin: "10px" }}>
                          <Tooltip
                            title='Disable the switch to avoid calling K-KISAN API'
                          >
                            <IconButton
                              color="primary"
                              aria-label="info"
                            >
                              <InfoIcon />
                            </IconButton>
                          </Tooltip>
                          <Switch callExtApi={callExtApi} onChange={handleToggleChange} />
                        </div>
                        :
                        <></>}
                      <div>
                        <BootstrapDialog
                          onClose={handleClose}
                          aria-labelledby="customized-dialog-title"
                          open={open}
                        >
                          <BootstrapDialogTitle id="customized-dialog-title" onClose={handleClose}>
                            Product Details
                          </BootstrapDialogTitle>
                          <DialogContent dividers>
                            <Grid container spacing={2} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                              <Grid item xs={6}>
                                <Item>Manufacturer Name</Item>
                              </Grid>
                              <Grid item xs={6}>
                                <Item>{product.manufacturername}</Item>
                              </Grid>
                              <Grid item xs={6}>
                                <Item>Supplier Name</Item>
                              </Grid>
                              <Grid item xs={6}>
                                <Item>{product.suppliername}</Item>
                              </Grid>
                              <Grid item xs={6}>
                                <Item>Category Name</Item>
                              </Grid>
                              <Grid item xs={6}>
                                <Item>{product.categoryname}</Item>
                              </Grid>
                              <Grid item xs={6}>
                                <Item>Sub Category Name</Item>
                              </Grid>
                              <Grid item xs={6}>
                                <Item>{product.subcategoryname}</Item>
                              </Grid>
                              <Grid item xs={6}>
                                <Item>Product Name</Item>
                              </Grid>
                              <Grid item xs={6}>
                                <Item>{product.productalias}</Item>
                              </Grid>
                              {product.brandname ? <>
                                <Grid item xs={6}>
                                  <Item>Brand Name</Item>
                                </Grid>
                                <Grid item xs={6}>
                                  <Item>{product.brandname}</Item>
                                </Grid> </> : <></>}
                              <Grid item xs={6}>
                                <Item>Unit of Measurement</Item>
                              </Grid>
                              <Grid item xs={6}>
                                <Item>{product.uomname}</Item>
                              </Grid>
                              <Grid item xs={6}>
                                <Item>Weight</Item>
                              </Grid>
                              <Grid item xs={6}>
                                <Item>{product.weight}</Item>
                              </Grid>
                              {product.licensenumber ? <>
                                <Grid item xs={6}>
                                  <Item>License Number</Item>
                                </Grid>
                                <Grid item xs={6}>
                                  <Item>{product.licensenumber}</Item>
                                </Grid> </> : <></>}
                              {product.websiteaddress ? <>
                                <Grid item xs={6}>
                                  <Item>Website Address</Item>
                                </Grid>
                                <Grid item xs={6}>
                                  <Item>{product.websiteaddress}</Item>
                                </Grid> </> : <></>}
                              {product.isstandard ? <>
                                <Grid item xs={6}>
                                  <Item>IS Standard</Item>
                                </Grid>
                                <Grid item xs={6}>
                                  <Item>{product.isstandard}</Item>
                                </Grid> </> : <></>}
                              {product.cmlnumber ? <>
                                <Grid item xs={6}>
                                  <Item>CM/L No.</Item>
                                </Grid>
                                <Grid item xs={6}>
                                  <Item>{product.cmlnumber}</Item>
                                </Grid> </> : <></>}
                              {product.gsm ? <>
                                <Grid item xs={6}>
                                  <Item>GSM</Item>
                                </Grid>
                                <Grid item xs={6}>
                                  <Item>{product.gsm}</Item>
                                </Grid> </> : <></>}
                              {product.bisstandard ? <>
                                <Grid item xs={6}>
                                  <Item>BIS Standard</Item>
                                </Grid>
                                <Grid item xs={6}>
                                  <Item>{product.bisstandard}</Item>
                                </Grid> </> : <></>}
                              {product.isstandardsofengine ? <>
                                <Grid item xs={6}>
                                  <Item>IS Standards of Engine</Item>
                                </Grid>
                                <Grid item xs={6}>
                                  <Item>{product.isstandardsofengine}</Item>
                                </Grid> </> : <></>}
                              {product.cmlnumberforengine ? <>
                                <Grid item xs={6}>
                                  <Item>CML Number for Engine</Item>
                                </Grid>
                                <Grid item xs={6}>
                                  <Item>{product.cmlnumberforengine}</Item>
                                </Grid> </> : <></>}
                              {product.isstandardsforpump ? <>
                                <Grid item xs={6}>
                                  <Item>IS Standards for Pump</Item>
                                </Grid>
                                <Grid item xs={6}>
                                  <Item>{product.isstandardsforpump}</Item>
                                </Grid> </> : <></>}
                              {product.cmlnumberforpump ? <>
                                <Grid item xs={6}>
                                  <Item>CML Number for Pump</Item>
                                </Grid>
                                <Grid item xs={6}>
                                  <Item>{product.cmlnumberforpump}</Item>
                                </Grid> </> : <></>}
                              {product.marketedby && product.marketedby !== "" ? <>
                                <Grid item xs={6}>
                                  <Item>Marketed By</Item>
                                </Grid>
                                <Grid item xs={6}>
                                  <Item>{product.marketedby}</Item>
                                </Grid> </> : <></>}
                              {product.cibregistrationcertificate && product.cibregistrationcertificate !== "" ? <>
                                <Grid item xs={6}>
                                  <Item>CIB Registration Certificate</Item>
                                </Grid>
                                <Grid item xs={6}>
                                  <Item>{product.cibregistrationcertificate}</Item>
                                </Grid> </> : <></>}
                              {product.seedsclassname && product.seedsclassname.length != 0 ? <>
                                <Grid item xs={6}>
                                  <Item>Seeds Class Name</Item>
                                </Grid>
                                <Grid item xs={6}>
                                  <Item>{product.seedsclassname}</Item>
                                </Grid>
                              </> : <></>}
                            </Grid>
                          </DialogContent>
                          <DialogActions>
                            <Button autoFocus onClick={handleClose}>
                              Confirm
                            </Button>
                          </DialogActions>
                        </BootstrapDialog>
                      </div>



                      <div>
                        <BootstrapDialog
                          onClose={handleConfirmScreenClose}
                          aria-labelledby="customized-dialog-title"
                          open={confirmScreenOpen}
                        >
                          <BootstrapDialogTitle id="customized-dialog-title" onClose={handleConfirmScreenClose}>
                            Please verify details. You Can not Edit the QR Code Details once you Submit
                          </BootstrapDialogTitle>
                          <DialogContent dividers>
                            <Grid container spacing={2} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                              <Grid item xs={6}>
                                <Item>Product Name</Item>
                              </Grid>
                              <Grid item xs={6}>
                                <Item>{productName}</Item>
                              </Grid>
                              <Grid item xs={6}>
                                <Item>Number of QR Codes</Item>
                              </Grid>
                              <Grid item xs={6}>
                                <Item>{prevData.codestobegenerated}</Item>
                              </Grid>
                              {prevData.batchnumber ? <>
                                <Grid item xs={6}>
                                  <Item>Batch Number</Item>
                                </Grid>
                                <Grid item xs={6}>
                                  <Item>{prevData.batchnumber}</Item>
                                </Grid>
                              </> : <></>}
                              {prevData.manufacturedate ? <>
                                <Grid item xs={6}>
                                  <Item>Manufacture Date</Item>
                                </Grid>
                                <Grid item xs={6}>
                                  <Item>{prevData.manufacturedate}</Item>
                                </Grid>
                              </> : <></>}
                              {prevData.expirydate ? <>
                                <Grid item xs={6}>
                                  <Item>Expiry Date</Item>
                                </Grid>
                                <Grid item xs={6}>
                                  <Item>{prevData.expirydate}</Item>
                                </Grid>
                              </> : <></>}
                              {prevData.mrp ? <>
                                <Grid item xs={6}>
                                  <Item>MRP</Item>
                                </Grid>
                                <Grid item xs={6}>
                                  <Item>{prevData.mrp}</Item>
                                </Grid>
                              </> : <></>}
                              {prevData.yearofmanufacturing ? <>
                                <Grid item xs={6}>
                                  <Item>Year of Manufacturing</Item>
                                </Grid>
                                <Grid item xs={6}>
                                  <Item>{prevData.yearofmanufacturing}</Item>
                                </Grid>
                              </> : <></>}
                              {prevData.serialnumberofthemachinery ? <>
                                <Grid item xs={6}>
                                  <Item>Serial number of the machinery</Item>
                                </Grid>
                                <Grid item xs={6}>
                                  <Item>{prevData.serialnumberofthemachinery}</Item>
                                </Grid>
                              </> : <></>}
                              {prevData.enginenumber ? <>
                                <Grid item xs={6}>
                                  <Item>Enigne Number</Item>
                                </Grid>
                                <Grid item xs={6}>
                                  <Item>{prevData.enginenumber}</Item>
                                </Grid>
                              </> : <></>}
                              {prevData.pumpnumber ? <>
                                <Grid item xs={6}>
                                  <Item>Pump Number</Item>
                                </Grid>
                                <Grid item xs={6}>
                                  <Item>{prevData.pumpnumber}</Item>
                                </Grid>
                              </> : <></>}
                              {prevData.engineislicencevalidity ? <>
                                <Grid item xs={6}>
                                  <Item>Engine ISlicence validity</Item>
                                </Grid>
                                <Grid item xs={6}>
                                  <Item>{prevData.engineislicencevalidity}</Item>
                                </Grid>
                              </> : <></>}
                              {prevData.pumpislicencevalidity ? <>
                                <Grid item xs={6}>
                                  <Item>Pump ISlicence validity</Item>
                                </Grid>
                                <Grid item xs={6}>
                                  <Item>{prevData.pumpislicencevalidity}</Item>
                                </Grid>
                              </> : <></>}
                              {prevData.chasisnumber ? <>
                                <Grid item xs={6}>
                                  <Item>Chasis Number</Item>
                                </Grid>
                                <Grid item xs={6}>
                                  <Item>{prevData.chasisnumber}</Item>
                                </Grid>
                              </> : <></>}
                              {prevData.serialnumberofthemachinery ? <>
                                <Grid item xs={6}>
                                  <Item>Serial Number of the Machinery</Item>
                                </Grid>
                                <Grid item xs={6}>
                                  <Item>{prevData.serialnumberofthemachinery}</Item>
                                </Grid>
                              </> : <></>}
                            </Grid>
                          </DialogContent>
                          <DialogActions>
                            <Button
                              onClick={handleConfirmAndSubmitScreenClose}
                              variant="contained"
                            >
                              Confirm and Submit
                            </Button>
                            <Button variant="contained" autoFocus onClick={handleConfirmScreenClose}>
                              Edit
                            </Button>
                          </DialogActions>
                        </BootstrapDialog>
                      </div>



                      <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        sx={{ mt: 3, mb: 2 }}
                      >
                        Generate QR codes
                      </Button>
                    </Box>
                  }
                </Box>
              </Container>
              <div style={{ width: '40%', backgroundColor: '#bbc0e3', border: '2px solid' }}>
                <Typography component="h1" variant="h5" style={{ marginTop: '10px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                  Guide to generate QR codes
                </Typography>
                <ul className='live' style={{ marginTop: '10px', display: 'flex', justifyContent: 'center', flexDirection: 'column' }}>
                  <li>Select The Product From the Product List To Generate QR Codes</li>
                  <li>Verify Details and Press Confirm or Press out side the box to Select another Product</li>
                  <li>Enter the Values for "Number of QR Codes", "Batch Number", "Manufacturing" Date and "Expiry Date"</li>
                  <li>Click on Generate QR Codes Button</li>
                  <li>Verify the Details and Click on Submit Button</li>
                  <li>After "QR Codes are generated successfully" if you wish to Print QR Codes Press Yes or Else No</li>
                  <li>Select the Label Size which you would like to Print, The Same Size Label will be Downloaded in PDF</li>
                  <li>Print the PDF file to Stickers using Your Label/Barcode Printer and Paste the Stickers on the Products</li>
                  <li>Please Verify one or Two QR Codes of every Batch using our Verify-Details option Before Sending the Product to RSK</li>
                </ul>
              </div>
            </div>
          </ThemeProvider>
          : <Loading />}
      </UserGuard>
    </div>
  );
}

export default PrimaryQR;
