import React, { createContext } from 'react';

const AuthContext = createContext()

export const AuthProvider = ({ children }) => {
    const [isAuthenticated, setIsAuthenticated] = React.useState(false);
    const [currentUser, setCurrentUser] = React.useState({});
    const [companyList, setCompanyList] = React.useState([]);
    const [uomidList, setuomidList] = React.useState([]);


    return (
        <AuthContext.Provider value={{
            isAuthenticated,
            setIsAuthenticated, 
            currentUser, 
            setCurrentUser,  
            companyList, 
            setCompanyList, 
            uomidList, 
            setuomidList,
        }}>
            {children}
        </AuthContext.Provider>
    )
};

export default AuthContext;