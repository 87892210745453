export const seedTable = [
    { name: "productCode", label: "Product Code", id: "productCode" },
    { name: "productAlias", label: "Product Alias", id: "productAlias" },
    { name: "manufacturerNameAuto", label: "Manufacturer Name", id: "manufacturerNameAuto" },
    { name: "supplierName", label: "Supplier Name", id: "supplierName" },
    { name: "brandName", label: "Brand Name", id: "brandName" },
    { name: "seedsClass", label: "Seeds Class", id: "seedsClass" }
]

export const fertlizerTable = [
    { name: "productCode", label: "Product Code", id: "productCode" },
    { name: "productAlias", label: "Product Alias", id: "productAlias" },
    { name: "manufacturerName", label: "Manufacturer Name", id: "manufacturerName" },
    { name: "supplierName", label: "Supplier Name", id: "supplierName" },
    { name: "brandName", label: "Brand Name", id: "brandName" },
]

export const pesticidesTable = [
    { name: "productCode", label: "Product Code", id: "productCode" },
    { name: "productAlias", label: "Product Alias", id: "productAlias" },
    { name: "manufacturerName", label: "Manufacturer Name", id: "manufacturerName" },
    { name: "supplierName", label: "Supplier Name", id: "supplierName" },
    { name: "brandName", label: "Brand Name", id: "brandName" },
    { name: "uomName", label: "Select UOM Name", id: "uomName" },
    { name: "weight", label: "Weight", id: "weight" },
    { name: "licensenumber", label: "License Number", id: "licensenumber" },
    { name: "marketedBy", label: "Marketed By", id: "marketedBy" },
    { name: "cibRegistrationCertificate", label: "CIB Registration Certificate", id: "cibRegistrationCertificate" },
]

export const chemicalsTable = [
    { name: "productCode", label: "Product Code", id: "productCode" },
    { name: "productAlias", label: "Product Alias", id: "productAlias" },
    { name: "manufacturerName", label: "Manufacturer Name", id: "manufacturerName" },
    { name: "supplierName", label: "Supplier Name", id: "supplierName" },
    { name: "brandName", label: "Brand Name", id: "brandName" },
    { name: "uomName", label: "Select UOM Name", id: "uomName" },
    { name: "weight", label: "Weight", id: "weight" },
    { name: "licensenumber", label: "License Number", id: "licensenumber" },
]

export const farmMachATable = [
    { name: "productCode", label: "Product Code", id: "productCode" },
    { name: "productAlias", label: "Product Alias", id: "productAlias" },
    { name: "manufacturerName", label: "Manufacturer Name", id: "manufacturerName" },
    { name: "supplierName", label: "Supplier Name", id: "supplierName" },
    { name: "brandName", label: "Brand Name", id: "brandName" },
    { name: "websiteAddress", label: "Website Address", id: "websiteAddress" },
]

export const farmMachBTable = [
    { name: "productCode", label: "Product Code", id: "productCode" },
    { name: "productAlias", label: "Product Alias", id: "productAlias" },
    { name: "manufacturerName", label: "Manufacturer Name", id: "manufacturerName" },
    { name: "supplierName", label: "Supplier Name", id: "supplierName" },
    { name: "brandName", label: "Brand Name", id: "brandName" },
    { name: "websiteAddress", label: "Website Address", id: "websiteAddress" },
]

export const farmMachCTable = [
    { name: "productCode", label: "Product Code", id: "productCode" },
    { name: "productAlias", label: "Product Alias", id: "productAlias" },
    { name: "manufacturerName", label: "Manufacturer Name", id: "manufacturerName" },
    { name: "supplierName", label: "Supplier Name", id: "supplierName" },
    { name: "brandName", label: "Brand Name", id: "brandName" },
    { name: "websiteAddress", label: "Website Address", id: "websiteAddress" },
    { name: "isStandardsOfEngine", label: "IS Standards of Engine", id: "isStandardsOfEngine" },
    { name: "cmlNumberForEngine", label: "CML Number for Engine", id: "cmlNumberForEngine" },
    { name: "isStandardsForPump", label: "IS Standards for Pump", id: "isStandardsForPump" },
    { name: "cmlNumberForPump", label: "CML Number For Pump", id: "cmlNumberForPump" },
]

export const tarpaulinsTable = [
    { name: "productCode", label: "Product Code", id: "productCode" },
    { name: "productAlias", label: "Product Alias", id: "productAlias" },
    { name: "manufacturerName", label: "Manufacturer Name", id: "manufacturerName" },
    { name: "supplierName", label: "Supplier Name", id: "supplierName" },
    { name: "brandName", label: "Brand Name", id: "brandName" },
    { name: "isStandard", label: "IS Standard", id: "isStandard" },
    { name: "cmlNo", label: "CM/L NO.", id: "cmlNo" },
    { name: "gsm", label: "GSM", id: "gsm" },
]

export const microIrrigationTable = [
    { name: "productCode", label: "Product Code", id: "productCode" },
    { name: "productAlias", label: "Product Alias", id: "productAlias" },
    { name: "manufacturerName", label: "Manufacturer Name", id: "manufacturerName" },
    { name: "supplierName", label: "Supplier Name", id: "supplierName" },
    { name: "brandName", label: "Brand Name", id: "brandName" },
    { name: "uomName", label: "Select UOM Name", id: "uomName" },
    { name: "weight", label: "Weight", id: "weight" },
    { name: "bisStandard", label: "BIS Standard", id: "bisStandard" },
    { name: "cmlNo", label: "CM/L NO.", id: "cmlNo" },
]


export const defaultTable = [
    { name: "productName", label: "Product Name", id:"productName"},
    { name: "productCode", label: "Product Code", id: "productCode" },
    { name: "productAlias", label: "Product Alias", id: "productAlias" },
    { name: "manufacturerName", label: "Manufacturer Name", id: "manufacturerName" },
    { name: "manufacturerId", label: "Manufacturer Id", id: "manufacturerId" },
    { name: "supplierName", label: "Supplier Name", id: "supplierName" },
    { name: "brandName", label: "Brand Name", id: "brandName" },
    { name: "uomName", label: "Select UOM Name", id: "uomName" },
    { name: "weight", label: "Weight", id: "weight" },
    { name: "bisStandard", label: "BIS Standard", id: "bisStandard" },
    { name: "cmlNo", label: "CM/L NO.", id: "cmlNo" },
    { name: "isStandard", label: "IS Standard", id: "isStandard" },
    { name: "gsm", label: "GSM", id: "gsm" },
    { name: "categoryId", label: "Category ID", id:"categoryId"},
    { name: "categoryName", label: "Category Name", id:"categoryName"},
    { name: "subCategoryId", label: "Sub Category ID", id:"subCategoryId"},
    { name: "subCategoryName", label: "Sub Category Name", id:"subCategoryName"},
    { name: "itemId", label: "Item ID", id:"itemId"},
    { name: "marketedBy", label: "Marketed By", id:"marketedBy"},
    { name: "licenceNumber", label: "Licence Number", id:"licenceNumber"},
    { name: "cibRegistrationCertificate", label: "CIB Registration Certificate", id:"cibRegistrationCertificate"},
    { name: "serialNo", label: "Serial No", id:"serialNo"},
    { name: "seedsClass", label: "Seeds Class", id: "seedsClass" }
]


export const groupList = [
    { id: 1, name: "SEEDS" },
    { id: 2, name: "FERTILIZER" },
    { id: 3, name: "PESTICIDES" },
    { id: 4, name: "CHEMICALS" },
    { id: 5, name: "Farm Machinery Part-A" },
    { id: 6, name: "Farm Machinery Part-B" },
    { id: 7, name: "Farm Machinery Part-C" },
    { id: 8, name: "TARPAULINS" },
    { id: 9, name: "MICRO IRRIGATION" },
]
