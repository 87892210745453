import React from "react";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import TableHead from '@mui/material/TableHead';
import Topbar from "../components/Topbar";
import PropTypes from 'prop-types';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableFooter from '@mui/material/TableFooter';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import IconButton from '@mui/material/IconButton';
import FirstPageIcon from '@mui/icons-material/FirstPage';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import LastPageIcon from '@mui/icons-material/LastPage';
import Button from '@mui/material/Button';
import AdminAuth from "../hooks/AdminAuth";
import { CSVLink } from 'react-csv'
import UserGuard from "../hooks/UserGuard";
import axios from 'axios'
import Loading from "../components/Loading";
import useAuth from "../hooks/useAuth";

import Snackbar from '@mui/material/Snackbar';
import CloseIcon from '@mui/icons-material/Close';


// 1. DATE OF QR CODE
// 2. INDIVIDUAL QR CODE
// 3. CONTAINER QR CODE
// 4. ProductName
// 5. BatchNumber
// 6. ManufactureDate
// 7. ExpiryDate


const columns = [
  { id: 'name', label: 'Name', minWidth: 100 },
  { id: 'email', label: 'Email', minWidth: 100 },
  {
    id: 'mobile',
    label: 'mobile',
    minWidth: 100,
    align: 'left',
  },
  {
    id: 'verified',
    label: 'Verified',
    minWidth: 100,
    align: 'center',
  },
  {
    id: 'company',
    label: 'Company',
    minWidth: 100,
    align: 'right',
  },
  {
    id: 'createat',
    label: 'Created At',
    minWidth: 100,
    align: 'right',
  },
  {
    id: 'activate',
    label: 'Activate User',
    minWidth: 100,
    align: 'right',
  },
  {
    id: 'deactivate',
    label: 'Deactivate User',
    minWidth: 100,
    align: 'right',
  }


];



function TablePaginationActions(props) {
  const theme = useTheme();
  const { count, page, rowsPerPage, onPageChange } = props;

  const handleFirstPageButtonClick = (event) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <Box sx={{ flexShrink: 0, ml: 2.5 }}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </Box>
  );
}

TablePaginationActions.propTypes = {
  count: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
};

// const rows = [
//   createData('India', 'IN', 1324171354, 3287263,3287263),
//   createData('China', 'CN', 1403500365, 9596961,3287263),
//   createData('Italy', 'IT', 60483973, 301340,3287263),
//   createData('United States', 'US', 327167434, 9833520,3287263),
//   createData('Canada', 'CA', 37602103, 9984670,3287263),
//   createData('Australia', 'AU', 25475400, 7692024,3287263),
//   createData('Germany', 'DE', 83019200, 357578,3287263),
//   createData('Ireland', 'IE', 4857000, 70273,3287263),
//   createData('Mexico', 'MX', 126577691, 1972550,3287263),
//   createData('Japan', 'JP', 126317000, 377973,3287263),
//   createData('France', 'FR', 67022000, 640679,3287263),
//   createData('United Kingdom', 'GB', 67545757, 242495,3287263),
//   createData('Russia', 'RU', 146793744, 17098246,3287263),
//   createData('Nigeria', 'NG', 200962417, 923768,3287263),
//   createData('Brazil', 'BR', 210147125, 8515767,3287263),
// ];



const UserReport = () => {
  // States
  const [fromDate, setFromDate] = React.useState('');
  const [toDate, setToDate] = React.useState('');
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [reqData, setReqData] = React.useState({});
  const [rows, setRows] = React.useState([]);
  const [loading, setLoading] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const [snackbarMessage, setSnackbarMessage] = React.useState("");
  const [activateUser, setActivateUser] = React.useState("");
  const [deActivateUser, setDeactivateUser] = React.useState("");
  const {currentUser, setCompanyList} = useAuth();


  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };

  const action = (
    <React.Fragment>
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={handleClose}
      >
        <CloseIcon fontSize="small" />
      </IconButton>
    </React.Fragment>
  );

  React.useEffect(() => {
    async function sendRequest() {
      const token = localStorage.getItem('token');
      console.log("inside sendReqeust")
      const configs = {
        headers: { 'Authorization': `Bearer ${token}` }
      }
      try {
        const res = await axios.post('/api/admin/getUserReport', reqData, configs);
        console.log(res);
        if (res.status === 200) {
          const userList = res.data;
          console.log(userList)
          setRows(userList);
          setOpen(true);
          setSnackbarMessage("Report generated successfully")
        }
        else {
          setOpen(true);
          setSnackbarMessage("Unknown error occurred")
        }
      }
      catch (error) {
        console.log(error)
        if (error.response) {
          setOpen(true)
          setSnackbarMessage(error.response.data.message)
        }
        else {
          setOpen(true);
          setSnackbarMessage(error.message)
        }
      }
      setLoading(false);
    }
    console.log(reqData, Object.keys(reqData).length, rows)
    if (reqData.length !== 0 && Object.keys(reqData).length !== 0) {
      setLoading(true);
      sendRequest();
    }
  }, [reqData])

  React.useEffect(() => {
    const token = localStorage.getItem('token');
    async function verifyUser() {
      const config = {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        }
      };
      try {
        const res = await axios.post(`/api/admin/deactivate/${activateUser}`, {adminEmail: currentUser.email}, config)
        if (res.status === 200) {
          setOpen(true);
          setSnackbarMessage("User activated successfully")
          console.log(res.data);
        }
        else {
          setOpen(true);
          setSnackbarMessage("Unknown error occured")
          console.log(res)
        }
      }
      catch (error) {
        if (error.response) {
          setOpen(true)
          setSnackbarMessage(error.response.data.message)
        }
        else {
          setOpen(true);
          setSnackbarMessage(error.message)
        }
      }
      sendRequest();
    }

    async function sendRequest() {
      const token = localStorage.getItem('token');
      console.log("inside sendReqeust")
      const configs = {
        headers: { 'Authorization': `Bearer ${token}` }
      }
      try {
        const res = await axios.post('/api/admin/getUserReport',{
          "fromDate": "2022-04-01",
          "toDate": "2025-04-01"
        }, configs);
        console.log(res);
        if (res.status === 200) {
          const userList = res.data;
          console.log(userList)
          setRows(userList);
        }
        else {
          setOpen(true);
          setSnackbarMessage("Unknown error occurred")
        }
      }
      catch (error) {
        console.log(error)
        if (error.response) {
          setOpen(true)
          setSnackbarMessage(error.response.data.message)
        }
        else {
          setOpen(true);
          setSnackbarMessage(error.message)
        }
      }
      setActivateUser("");
      setLoading(false);
    }

    if (activateUser !== undefined && activateUser.length !== 0) {
      setLoading(true);
      verifyUser()
    }
  }, [activateUser])


  React.useEffect(() => {
    const token = localStorage.getItem('token');
    async function verifyUser() {
      const config = {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        }
      };
      try {
        const res = await axios.post(`/api/admin/verify/${deActivateUser}`, {adminEmail: currentUser.email}, config)
        if (res.status === 200) {
          setOpen(true);
          setSnackbarMessage("User activated successfully")
          console.log(res.data);
        }
        else {
          setOpen(true);
          setSnackbarMessage("Unknown error occured")
          console.log(res)
        }
      }
      catch (error) {
        if (error.response) {
          setOpen(true)
          setSnackbarMessage(error.response.data.message)
        }
        else {
          setOpen(true);
          setSnackbarMessage(error.message)
        }
      }
      sendRequest();
    }

    async function sendRequest() {
      const token = localStorage.getItem('token');
      console.log("inside sendReqeust")
      const configs = {
        headers: { 'Authorization': `Bearer ${token}` }
      }
      try {
        const res = await axios.post('/api/admin/getUserReport',{
          "fromDate": "2022-04-01",
          "toDate": "2025-04-01"
        }, configs);
        console.log(res);
        if (res.status === 200) {
          const userList = res.data;
          console.log(userList)
          setRows(userList);
        }
        else {
          setOpen(true);
          setSnackbarMessage("Unknown error occurred")
        }
      }
      catch (error) {
        console.log(error)
        if (error.response) {
          setOpen(true)
          setSnackbarMessage(error.response.data.message)
        }
        else {
          setOpen(true);
          setSnackbarMessage(error.message)
        }
      }
      setDeactivateUser("");
      setLoading(false);
    }

    if (deActivateUser !== undefined && deActivateUser.length !== 0) {
      setLoading(true);
      verifyUser()
    }
  }, [deActivateUser])



  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };


  const handleSubmit = () => {
    if (fromDate === "") {
      setOpen(true);
      setSnackbarMessage("Please enter from date")
    }
    else if (toDate === "") {
      setOpen(true);
      setSnackbarMessage("Please enter to date")
    }
    else {
      const reqPayload = {
        "fromDate": fromDate.format('YYYY-MM-DD'),
        "toDate": toDate.format('YYYY-MM-DD')
      }
      setReqData(reqPayload);
    }
  }

  const handleUserActivation = (email) => {
    setActivateUser(email);
  }

  const handleUserDeactivation = (email) => {
    setDeactivateUser(email);
  } 


  return (
    <div>
      {open === true ? <Snackbar
        open={open}
        autoHideDuration={6000}
        onClose={handleClose}
        message={snackbarMessage}
        action={action}
      /> : <></>}
      <UserGuard>
        <AdminAuth>
          <Topbar />
          <div style={{ "marginTop": "20px", "display": "flex" }}>
            <div style={{ "margin": "10px" }}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  label="from date"
                  value={fromDate}
                  onChange={(newValue) => setFromDate(newValue)}
                  format="DD/MM/YYYY"
                />
              </LocalizationProvider>
            </div>
            <div style={{ "margin": "10px" }}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  label="to date"
                  value={toDate}
                  onChange={(newValue) => setToDate(newValue)}
                  format="DD/MM/YYYY"
                />
              </LocalizationProvider>
            </div>
            <div style={{ "margin": "10px", "marginTop": "20px" }}>
              <Button
                variant="contained"
                onClick={handleSubmit}
              >Submit</Button>
            </div>
            {rows.length === 0 ? <></> : <div style={{ "margin": "10px", "marginTop": "20px" }}>
              <Button
                variant="contained" style={{ textDecoration: 'none', color: '#FFF' }}
              ><CSVLink data={rows} filename={"user-report.csv"} style={{ textDecoration: 'none', color: '#FFF' }}>Download Report</CSVLink></Button>
            </div>}
          </div>
          <div style={{ "marginTop": "40px" }}>
            {loading === true ? <Loading /> :
              <TableContainer component={Paper}>
                {rows.length === 0 ? <></> :
                  <Table sx={{ minWidth: 500 }} stickyHeader aria-label="sticky table">
                    <TableHead>
                      <TableRow>
                        {columns.map((column) => (
                          <TableCell
                            key={column.id}
                            align={column.align}
                            style={{ minWidth: column.minWidth }}
                          >
                            {column.label}
                          </TableCell>
                        ))}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {(rowsPerPage > 0
                        ? rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                        : rows
                      ).map((row) => (
                        <TableRow key={row._id}>
                          <TableCell style={{ width: 100 }} align="left">
                            {row.firstname + row.lastname}
                          </TableCell>
                          <TableCell style={{ width: 100 }} align="left">
                            {row.email}
                          </TableCell>
                          <TableCell style={{ width: 100 }} align="left">
                            {row.mobile}
                          </TableCell>
                          <TableCell style={{ width: 100 }} align="center">
                            {row.verified}
                          </TableCell>
                          <TableCell style={{ width: 100 }} align="right">
                            {row.company}
                          </TableCell>
                          <TableCell style={{ width: 100 }} align="right">
                            {String(row.createdat).slice(3)}
                          </TableCell>
                          <TableCell style={{ width: 100 }} align="right">
                            <Button
                              variant="contained"
                              color="primary"
                              onClick={() => handleUserDeactivation(row.email)}
                              disabled={row.verified === 'true'?true:false}
                            >
                              Activate User
                            </Button>
                          </TableCell>
                          <TableCell style={{ width: 100 }} align="right">
                            <Button
                              variant="contained"
                              color="primary"
                              onClick={() => handleUserActivation(row.email)}
                              disabled={row.verified === 'true'?false:true}
                            >
                              Deactivate User
                            </Button>
                          </TableCell>
                        </TableRow>
                      ))}

                      {emptyRows > 0 && (
                        <TableRow style={{ height: 53 * emptyRows }}>
                          <TableCell colSpan={6} />
                        </TableRow>
                      )}
                    </TableBody>
                    <TableFooter>
                      <TableRow>
                        <TablePagination
                          rowsPerPageOptions={[5, 10, 25, { label: 'All', value: -1 }]}
                          colSpan={3}
                          count={rows.length}
                          rowsPerPage={rowsPerPage}
                          page={page}
                          SelectProps={{
                            inputProps: {
                              'aria-label': 'rows per page',
                            },
                            native: true,
                          }}
                          onPageChange={handleChangePage}
                          onRowsPerPageChange={handleChangeRowsPerPage}
                          ActionsComponent={TablePaginationActions}
                        />
                      </TableRow>
                    </TableFooter>
                  </Table>
                }
              </TableContainer>
            }
          </div>
        </AdminAuth>
      </UserGuard>
    </div>
  )
}

export default UserReport;