import React from "react";

const Loading = () =>{
    return(
        <div style={{display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height:"75vh",
                width:"100%"
              }}><img style={{height:"400px",
              width:"400px"}} src={require('../load.gif')} alt="loading..." /></div>
    )
}

export default Loading;